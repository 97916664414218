import {combineReducers} from 'redux';
import alert from 'src/features/alert';
import events from 'src/features/events';
import partners from 'src/features/partners';
import talents from 'src/features/talents';
import members from 'src/features/members';
import articles from 'src/features/articles';
import main from 'src/features/main';

const rootReducer = combineReducers({
  alert,
  events,
  partners,
  talents,
  members,
  articles,
  main,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
