import {
  configureStore,
  AnyAction,
} from '@reduxjs/toolkit';
import {createEpicMiddleware} from 'redux-observable';
import rootReducer from './rootReducer';

const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, any, any>();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
