/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {memo, useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Event, loadEventByTitle} from 'src/features/events';
import styles from './EventCard.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface EventCardProps {
  event: Event;
  width: number;
}

const EventCard = memo(({event, width}: EventCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const startDate = new Date(event.start_date);

  const {pathname} = useLocation();
  const {deviceType} = useAppSelector((s) => s.main);

  const [hoveredCardTitleContainer, setHoveredCardTitleContainer] = useState('');

  // make cardTitle "bottom : 0" when pathname === "/realisations"
  useEffect(() => {
    const cardTitleContainers = document.querySelectorAll(`.${styles.cardTitleContainer}`) as NodeListOf<HTMLElement>;
    if (cardTitleContainers) {
      cardTitleContainers.forEach((cardTitleContainer:HTMLElement) => {
        if ((pathname === '/realisations' && deviceType === 'mobile')
            || (pathname === '/' && deviceType === 'mobile')) {
          cardTitleContainer.style.bottom = '0px';
          cardTitleContainer.style.top = '';
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          pathname === '/realisations' ? cardTitleContainer.style.top = '40px' : cardTitleContainer.style.top = '-10px';
          cardTitleContainer.style.bottom = '';
          // cardTitleContainer.style.top = "-10px"
        }
      });
    }
  });

  return (
      <div className={styles.cardItem} style={{width: `${width}px`, height: `${width}px`}}>
        <div
        className={
          [
            styles.cardTitleContainer,
            hoveredCardTitleContainer ? styles.hoveredCardTitleContainer : '',
          ].join(' ')}
        >
          <div className={styles.cardTitle}>
            <p className={styles.clientTitle}>{event.title} &nbsp;</p>
            <p>
              {event.clients.map((client, i) => {
                if (i < 2) {
                  if (i < 1 && i < event.clients.length - 1) return (<span key={client._id}>{client.title} - </span>);
                  return (<span key={client._id}>{client.title}</span>);
                }
                return null;
              })}
            </p>
          </div>
          <div className={styles.date}>
            <p>{startDate.getMonth() + 1 < 10 ? `0${startDate.getMonth() + 1}` : `${startDate.getMonth() + 1}`} {` | ${startDate.getFullYear()}`}</p>
          </div>
        </div>
        <LazyLoadImage
          onClick={() => {
            navigate(`/realisations/${event.title}`);
            dispatch(loadEventByTitle(event.title));
          }}
          onMouseEnter={() => { setHoveredCardTitleContainer(event.title); }}
          onMouseLeave={() => { setHoveredCardTitleContainer(''); }}
          src={event.thumbnail || event.clients[0].imageUrl}
          // src="https://www.ecopro-ascenseurs.fr/wp-content/uploads/2017/12/carre-bleu-ciel.png"
          alt={event.description}
          className={[styles.image,
          ].join(' ')}
        />
      </div>
  );
});

export default EventCard;
