/* eslint-disable no-param-reassign */
import {v4 as uuidv4} from 'uuid';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from './helpers';

// values must equal to framework(bootstrap) color variants or add check in alert component to map names to framework color variants
export enum AlertTypes {
  error = 'danger',
  success = 'success',
  warning = 'warning',
}

export interface AlertStatus {
  message: string;
  alertType: 'success' | 'danger' | 'warning';
  id: string;
}

const initialState: { [alert: string]: AlertStatus } = {};

const alert = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert(state, {payload}: PayloadAction<AlertStatus>) {
      state[payload.id] = payload;
    },
    removeAlert(state, {payload}: PayloadAction<string>) {
      delete state[payload];
    },
  },
});
export const {setAlert, removeAlert} = alert.actions;

export default alert.reducer;

export const createAlert = (message: string, alertType: AlertTypes, timeout = 5000): AppThunk => (dispatch) => {
  const id = uuidv4();
  const payload = {message, alertType, id};
  dispatch(setAlert(payload));
  setTimeout(() => dispatch(removeAlert(id)), timeout);
};
