/* eslint-disable max-len */
import {Helmet} from 'react-helmet';
import Footer from 'src/components/Footer';
import styles from './CGU.module.css';

const CGU = () => (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>ATP CGU</title>
        <meta
          name="description"
          content="Nos Condtion Générales d'Utilisation"
        />
      </Helmet>
      <div className={styles.contentContainer}>
      <p>
      <h3 className={styles.center}>CONDITIONS GÉNÉRALES</h3>
      <br/>
      <br/>
      <div className={styles.introduction}>
            Les présentes conditions générales régissent l&apos;utilisation de ce site www.alttabprod.com.
          <br/>Ce site appartient et est géré par Alt Tab Productions.
          <br/>En utilisant ce site, vous indiquez que vous avez lu et compris les conditions d&apos;utilisation et que vous acceptez de les respecter en tout temps.
          <br/>Type de site : vitrine
      </div>
      </p>
      <div>
        <p >
          <h4 className={styles.title}>
            Propriété intellectuelle
          </h4>
          Tout contenu publié et mis à disposition sur ce site est la propriété de Alt Tab Productions et de ses créateurs.
          Cela comprend, mais n&apos;est pas limité aux images, textes, logos, documents, fichiers téléchargeables et tout ce qui contribue
          à la composition de ce site.
        </p>
        </div>
        <div>
          <p>
            <h4 className={styles.title}>
              Limitation de responsabilité
            </h4>
            Alt Tab Productions ou l&apos;un de ses employés sera tenu responsable de tout problème découlant de ce site.
            Néanmoins, Alt Tab Productions et ses employés ne seront pas tenus responsables de tout problème découlant de toute
            utilisation irrégulière de ce site.
          </p>
        </div>
        <div>
          <p>
            <h4 className={styles.title}>
              Indemnité
            </h4>
            En tant qu&apos;utilisateur, vous indemnisez par les présentes Alt Tab Productions de toute responsabilité, de tout coût,
            de toute cause d&apos;action, de tout dommage ou de toute dépense découlant de votre utilisation de ce site ou de votre
            violation de l&apos;une des dispositions énoncées dans le présent document.
          </p>
        </div>
        <div>
            <p>
              <h4 className={styles.title}>
                Lois applicables
              </h4>
              Ce document est soumis aux lois applicables en France et vise à se conformer à ses règles et règlements nécessaires.
              Cela inclut la réglementation à l&apos;échelle de l&apos;UE énoncée dans le RGPD.
            </p>
        </div>
        <div>
          <p>
            <h4 className={styles.title}>
              Divisibilité
            </h4>
            Si, à tout moment, l&apos;une des dispositions énoncées dans le présent document est jugée incompatible ou
            invalide en vertu des lois applicables, ces dispositions seront considérées comme nulles et seront retirées du présent document. Toutes les autres dispositions ne seront pas touchées par les lois et le reste du document sera toujours considéré comme valide.
          </p>
        </div>
        <div>
          <p>
            <h4 className={styles.title}>
              Modifications
            </h4>
            Ces conditions générales peuvent être modifiées de temps à autre afin de maintenir le respect de la loi et de refléter tout changement à la façon dont nous gérons notre site et la façon dont nous nous attendons à ce que les utilisateurs se comportent sur notre site. Nous recommandons à nos utilisateurs de vérifier ces conditions générales de temps à autre pour s&apos;assurer qu&apos;ils sont informés de toute mise à jour. Au besoin, nous informerons les utilisateurs par courriel des changements apportés à ces conditions ou nous afficherons un avis sur notre site.
          </p>
        </div>
        <div>
          <p>
            <h4 className={styles.title}>Contact</h4>
            Veuillez communiquer avec nous si vous avez des questions ou des préoccupations. Nos coordonnées sont les suivantes :
            <br />
            <br />
            mail: contact@alttabprod.com
            <br/>
            <br/>
            Adresse: 30 Boulevard Paul Vaillant Couturier, 94200, Ivry-Sur-Seine
          </p>
        </div>
        <p>
          <span>Date d&apos;entrée en vigueur : </span>le 11 janvier 2022.
        </p>
      </div>
      <Footer />

    </div>
);

export default CGU;
