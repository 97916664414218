/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {Event} from './events';
import {AppThunk} from './helpers';

export interface Partner {
  _id: string;
  title: string;
  description: string;
  date: Date;
  imageUrl: string;
  events: Event[];
  displayRank: number;
}

export interface PartnerSimple {
  _id: string;
  title: string;
  description: string;
  date: Date;
  imageUrl: string;
  events: string[];
  displayRank: number;
}
export interface PartnerState {
  partnersList: Partner[];
  partnersListSimple: PartnerSimple[];
  homePagePartner: string;

}

const initialState: PartnerState = {
  partnersList: [],
  partnersListSimple: [],
  homePagePartner: '',
};

const partners = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    partnersLoaded(state, {payload}: PayloadAction<Partner[]>) {
      state.partnersList = payload;
    },
    simplePartnersLoaded(state, {payload}: PayloadAction<PartnerSimple[]>) {
      state.partnersListSimple = payload;
    },
    setHomePagePartner(state, {payload}: PayloadAction<string>) {
      state.homePagePartner = payload;
    },
  },
});

export const {partnersLoaded, simplePartnersLoaded, setHomePagePartner} = partners.actions;

export default partners.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadPartnersList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/partners/fetch/all`);
    dispatch(partnersLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadPartnersListSimple = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/partners/fetch/all/simple`);
    dispatch(simplePartnersLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};
