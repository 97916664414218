/* eslint-disable no-underscore-dangle */
import {useEffect, useState} from 'react';
import Footer from 'src/components/Footer';
import {Link} from 'react-router-dom';
import {RootState} from 'src/app/rootReducer';
import {Article, loadArticlesList} from 'src/features/articles';
import styles from './MediaPage.module.css';
import ogaminglogo from '../../assets/logo/ogaminglogo.png';
import oglollogo from '../../assets/logo/oglollogo.png';
import ogsclogo from '../../assets/logo/ogsclogo.png';
import ritoglogo from '../../assets/logo/ritoglogo.png';
import {Helmet} from 'react-helmet';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const MediaPage = () => {
  const OGamingItems = [
    {
      name: 'OGaming',
      imageUrl: ogaminglogo,
      link: 'https://www.twitch.tv/ogamingtv',
    },
    {
      name: 'OG LOL',
      imageUrl: oglollogo,
      link: 'https://www.twitch.tv/ogaminglol',
    },
    {
      name: 'OGSC',
      imageUrl: ogsclogo,
      link: 'https://www.twitch.tv/ogamingsc2',
    },
    {
      name: "Rito'G",
      imageUrl: ritoglogo,
      link: 'https://www.twitch.tv/ritogaming',
    },
  ];

  const dispatch = useAppDispatch();
  const {articlesList} = useAppSelector((s: RootState) => s.articles);
  const newArticlesList = [...articlesList];
  const sortedList = newArticlesList.sort((a, b) => a.displayRank - b.displayRank);
  const [currentArticle, setCurrentArticle] = useState<Article>();


  useEffect(() => {
    dispatch(loadArticlesList());
  }, [dispatch]);

  const [selectedTab, setSelectedTab] = useState(1);
  const Tab = () => (
    <div className={styles.pageContainer}>
      <div className={styles.mediaTabDescription}>
      <div className={`${selectedTab === 1 ? '' : 'd-none'}`}>
        <p>
          Fondée en 2010, O&apos;Gaming est devenu le média pionnier de
          l&apos;e-sport en France avec près de 2.2 millions de visiteurs
          uniques.
        </p>
        <p>
          Nous diffusons 24/24h sur la plateforme Twitch des parties de
          jeux vidéo, des talk-shows et des compétitions d&apos;e-sport.
        </p>
      </div>
      <div className={`${selectedTab === 2 ? '' : 'd-none'}`}>
        <p>
          Depuis notre création, Alt Tab a été mentionné 191.000 fois sur
          Google.
        </p>
        <p>Rassurez-vous, on vous a fait une petite compil.</p>
      </div>
      </div>
      <div
        className={
          selectedTab === 1
            ? styles.logoTabContent
            : styles.articlesTabContent
        }
      >
        {OGamingItems.map((item) => (
          <div className={`${styles.circleItemContainer} ${selectedTab === 1 ? '' : 'd-none'}`} key={item.name}>
            <div className={styles.circlesContainer}>
              <span className={styles.backgroundCircle1} />
              <Link to={{pathname: item.link}} target="_blank">
                <img
                  className={styles.circleItemImage}
                  src={item.imageUrl}
                  alt={item.name}
                />
              </Link>
              <div className={styles.itemNameContainer}>
                <h3 className={styles.itemName}>{item.name}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={[styles.articleTitle, currentArticle ? styles.hidden : ''].join(' ')} >
          <span className={styles.visitButton}>{currentArticle?.title}</span>
      </div>
      <div className={`row ${styles.articlesListContainer}`}>
        {sortedList.map((item) => (
          <div className={`col-xl-3 col-md-4 col-lg-4 col-sm-6 ${styles.articleItemContainer} ${selectedTab === 2 ? '' : 'd-none'}`}>
            <a rel="noreferrer" target="_blank" href={item.url}>
              <div
                key={item._id}
                onMouseEnter={() => {
                  setCurrentArticle(item);
                }}
                onMouseLeave={() => {
                  setCurrentArticle(undefined);

                }}
              >
                <img
                  className={styles.articleItemImage}
                  src={item.image}
                  alt={item.title}
                />

              </div>
            </a>
          </div>

        ))}
      </div>
    </div>
  );

  return (
    <div className={styles.contentFooterContainer}>
      <Helmet>
        <title>ATP Média</title>
        <meta
          name="description"
          content="Les médias d'Alt Tab sont la vitrine de son savoir faire. Depuis plus de 10 ans, O'Gaming divertit les foules avec passion et ambition."
        />
      </Helmet>
      <div className={styles.scrollContainer}>
        <h3 className={styles.mediaPageSideTitle}>
          Nos <span style={{fontFamily: 'HussarBold'}}>médias</span>
        </h3>
        <div className={styles.mediaPageContainer}>
          <div className={styles.mediaPageHeader}>
            <div className={styles.mediaPageNav}>
              <a
                // className={selectedTab === 1 ? styles.mediaActiveTab : ''}
                style={{textAlign: 'right', justifyContent: 'flex-end'}}
                className={[selectedTab === 1 && styles.mediaActiveTab].join(' ')}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(1);
                }}
                href="/"
              >
                <p>O&apos;GAMING</p>
              </a>
              <span style={{fontSize: '2rem', margin: 'auto 3rem auto 3rem'}}>
                •
              </span>
              <a
                style={{textAlign: 'left', justifyContent: 'flex-start'}}
                className={selectedTab === 2 ? styles.mediaActiveTab : ''}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(2);
                }}
                href="/"
              >
                <p>PRESSE</p>
              </a>
            </div>
          </div>
          <Tab />
        </div>

        <Footer />
      </div>
    </div>

  );
};

export default MediaPage;
