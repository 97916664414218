import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux';
import store from 'src/app/store';
import {BrowserRouter} from 'react-router-dom';
import App from './app/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/fonts/HussarBold.otf';
import './assets/fonts/HussarBoldOutline.otf';

ReactDOM.render(
  <StrictMode>
      <Provider store={store}>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </Provider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
