import './App.css';
import RealisationPage from 'src/pages/RealisationPage';
import Navbar from 'src/components/Navbar';
import ProjectDetails from 'src/components/ProjectDetails';
import AgencePage from 'src/pages/AgencePage';
import MembersPage from 'src/pages/MembersPage';
import VisionPage from 'src/pages/VisionPage';
import {useDispatch} from 'react-redux';
import MediaPage from 'src/pages/MediaPage';
import {deviceIsMobile, deviceIsDesktop} from 'src/features/main';
import MentionsLegales from 'src/pages/MentionsLegales/MentionsLegales';
import CGU from 'src/pages/CGU/CGU';
import CustomAlert from 'src/components/Alert/Alert';
import NotFoundPage from 'src/pages/NotFoundPage';
import HomePage from '../pages/HomePage';
import ContactPage from '../pages/ContactPage';
import {Route, Routes} from 'react-router-dom';

function App() {
  const dispatch = useDispatch();

  // Detect user device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) { dispatch(deviceIsMobile()); } else { dispatch(deviceIsDesktop()); }

  return (
    <div className="App">
      <CustomAlert />
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/media" element={<MediaPage />} />
        <Route path="/realisations" element={<RealisationPage />} />
        <Route path="/realisations/:title" element={<ProjectDetails />} />
        <Route path="/members" element={<MembersPage />} />
        <Route path="/agence" element={<AgencePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/vision" element={<VisionPage />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/CGU" element={<CGU />} />
        <Route path="" element={<NotFoundPage />} />
      </Routes>

    </div>
  );
}
export default App;
