// /* eslint-disable jsx-a11y/no-static-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
import {useState, useEffect} from 'react';
import Aos from 'aos';
import {useSelector} from 'react-redux';
import {RootState} from 'src/app/rootReducer';
import Footer from 'src/components/Footer';
import styles from './VisionPage.module.css';
import {Helmet} from 'react-helmet';

enum TabTypes {
  WHO_ARE_WE = 'QUI SOMMES-NOUS ?',
  HISTOIRE = 'NOTRE HISTOIRE',
  VALEURS = 'NOS VALEURS',
}
const VisionPage = () => {
  const [currentTab, setCurrentTab] = useState(TabTypes.WHO_ARE_WE);
  const {deviceType} = useSelector((s: RootState) => s.main);
  Aos.init({duration: 1000});

  // Mobile
  useEffect(() => {
    if (deviceType === 'mobile') {
      const background2Mobile = document.querySelector(`.${styles.background2Mobile}`) as HTMLElement;
      const gridContainerMobile = document.querySelector(`.${styles.gridContainerMobile}`) as HTMLElement;
      if (background2Mobile && gridContainerMobile) {
        //
        // Handle Grid Position Absolute / Fixed
        //
        window.addEventListener('scroll', () => {
          const rect = background2Mobile.getBoundingClientRect();
          const out = rect.bottom - window.innerHeight;
          if (rect.top > 0) {
            gridContainerMobile.style.position = 'absolute';
            console.log('absolute');
          }
          if (rect.top < 0 && (out > 0)) {
            console.log('fixed');
            gridContainerMobile.style.position = 'fixed';
          }
          gridContainerMobile.style.transform = `translateY(${-20 * (window.innerHeight / rect.bottom)}%)`;

          //
          // Handle Current Tab
          //
          const total = background2Mobile.offsetHeight;
          const current = (background2Mobile.getBoundingClientRect().top * -1);
          let firstTier;
          let secondTier;
          let thirdTier;
          if (current && total) {
            // firstTier = current > total * 0.8;
            // secondTier = current > total * 0.6 && current < total * 0.8;
            // thirdTier = current > total * 0.4 && current < total * 0.6;
            firstTier = current < total * 0.25;
            secondTier = current > total * 0.25 && current < total * 0.5;
            thirdTier = current > total * 0.5;
          }
          if (firstTier && currentTab !== TabTypes.WHO_ARE_WE) setCurrentTab(TabTypes.WHO_ARE_WE);
          else if (secondTier && currentTab !== TabTypes.HISTOIRE) setCurrentTab(TabTypes.HISTOIRE);
          else if (thirdTier && currentTab !== TabTypes.VALEURS) setCurrentTab(TabTypes.VALEURS);
          else if (!thirdTier && !firstTier && !secondTier && currentTab !== TabTypes.WHO_ARE_WE) {
            setCurrentTab(TabTypes.WHO_ARE_WE);
          }
        });
      }
    }
  });


  const showGridContainerDesktop = () => (
      <div className={[styles.gridContainerDesktop].join(' ')}>

        <div className={styles.gridTitleContainer}>
          <div
            onClick={() => setCurrentTab(TabTypes.WHO_ARE_WE)}
            className={[
              styles.gridTitle,
              styles.gridTab1,
              currentTab === TabTypes.WHO_ARE_WE && styles.gridTitleSelected,
            ].join(' ')}
          >
            <h3>QUI SOMMES NOUS</h3>
          </div>
          <div
            onClick={() => setCurrentTab(TabTypes.HISTOIRE)}

            className={[
              styles.gridTitle,
              styles.gridTab2,
              currentTab === TabTypes.HISTOIRE && styles.gridTitleSelected,
            ].join(' ')}
          >
            <h3>NOTRE HISTOIRE</h3>
          </div>
          <div
            onClick={() => setCurrentTab(TabTypes.VALEURS)}
            className={[
              styles.gridTitle,
              styles.gridTab3,
              currentTab === TabTypes.VALEURS && styles.gridTitleSelected,
            ].join(' ')}
          >
            <h3>NOS VALEURS</h3>
          </div>
        </div>
        <div className={styles.gridTextContainer}>
          <div
            className={[
              currentTab === TabTypes.WHO_ARE_WE
                ? styles.gridText
                : styles.gridTextHidden,
              currentTab === TabTypes.WHO_ARE_WE && styles.animateGridText,
            ].join(' ')}
          >
            <p>
              Alt Tab est depuis 10 ans l&apos;acteur central de l&apos;eSport et du streaming francophones. Centralisant toutes les
              énergies, tous les métiers et toutes les étapes de la création des spectacles de demain, nous sommes le pivot
              entre, talents, partenaires et publics, pour produire des moments inoubliables.
            </p>
            <p>

              La plupart des grands talents du streaming contemporain sont passés par nos murs, et dans notre agence de
              talent naissent les génies de demain. Nous produisons des spectacles, des tournois, des clips musicaux, des
              vidéos humoristiques, des formats publicitaires, des émissions originales, de la conception éditoriale au
              community management, en passant par tous les métiers intermédiaires.
            </p>
            <p>

              Imaginant les événements marquants des industries du jeu vidéo et du streaming, notre équipe de talent et
              son expérience unique vous accompagnent de vos premiers pas en live jusqu&apos;aux plus grandes salles de spectacle
              d&apos;Europe.

            </p>
          </div>
          <div
            className={[
              currentTab === TabTypes.HISTOIRE
                ? styles.gridText
                : styles.gridTextHidden,
              currentTab === TabTypes.HISTOIRE && styles.animateGridText,
            ].join(' ')}
          >
            <p>
              Comme bien des success stories entrepreneuriales du 21eme siècle, Alt Tab commence dans une chambre d&apos;ado,
              quand deux frères, Pomf et Thud, décident en 2010 de commenter en direct des matchs du jeu vidéo Starcraft 2.
              Leur succès grandissant leur ouvre de plus en plus de portes, et agrège autour d&apos;eux toute une communauté de
              ceux qui seront bientôt les superstar du net.
            </p>
            <p>

              Réalisant que la production pure et la diffusion sont bien deux métiers,
              ils créent bientôt O&apos;Gaming, un nouveau média indépendant, une galaxie de chaînes Twitch
              retransmettant les plus beaux moments du jeu vidéo compétitif devenu sport électronique. L&apos;eSport,
              cette myriade de disciplines qui font vibrer les foules est le cœur de métier d&apos;O&apos;Gaming et fait donc
              partie de l&apos;ADN d&apos;Alt Tab.
            </p>
            <p>

              Aujourd&apos;hui, Alt Tab est plus que jamais une société innovante, mêlant un dynamisme hors du commun et
              une expérience unique dans nos industries.
            </p>
          </div>
          <div
            className={[
              currentTab === TabTypes.VALEURS
                ? styles.gridText
                : styles.gridTextHidden,
              currentTab === TabTypes.VALEURS && styles.animateGridText,
            ].join(' ')}
          >
            <p>
              Toutes les personnes travaillant avec Alt Tab productions, en interne ou en externe, doivent adhérer à notre
              charte de déontologie. Ce document public engage, par chacun de ses paragraphes, les personnes participant à
              nos productions à ne pas discriminer, rester humble, respecter la confidentialité et à demander et respecter
              le consentement de celles et ceux qui l&apos;entourent.
            </p>

            <p>

              Par cette charte, nous participons activement à bâtir une industrie du streaming dynamique, pérenne et moderne,
              porteuse de valeurs essentielles, respectant celles et ceux qui sont les fers de lance de ces nouvelles
              cultures.

            </p>
            <p className={styles.downloadButtonP}>
              <a href="http" className={styles.buttonElement}>
                <span className={styles.borderDLButton} />
                <span className={styles.topDLButton} />
                <span className={styles.rightDLButton} />
                <span className={styles.bottomDLButton} />
                <span className={styles.leftDLButton} />
                <a href="/files/Deontologie_AltTab.pdf" className={styles.textDLButton} target="_blank">Télécharger notre charte</a>
              </a>
            </p>

          </div>
        </div>
      </div>
  );
  const showGridContainerMobile = () => (
      <div className={[styles.gridContainerMobile].join(' ')}>
        <div className={styles.gridContentMobile}>
          {/*
          QUI SOMMES NOUS
          */}
          <div className={[styles.tileMobile, currentTab === TabTypes.WHO_ARE_WE ? styles.activeTileMobile : ''].join(' ')}>
            <div className={[styles.gridTitleMobile, currentTab === TabTypes.WHO_ARE_WE ? styles.activeTitleMobile : ''].join(' ')}>
              <h3>QUI SOMMES NOUS</h3>
            </div>

            <div className={[styles.gridTextMobile].join(' ')}>
              <p className={styles.gridTextContentMobile}>
              Alt Tab est depuis 10 ans l&apos;acteur central de l&apos;eSport et du streaming francophones. Centralisant toutes les énergies,
              tous les métiers et toutes les étapes de la création des spectacles de demain, nous sommes le pivot entre, talents,
              partenaires et publics, pour produire des moments inoubliables.

              </p>
            </div>
          </div>
          {/*
          NOTRE HISTOIRE
          */}
          <div className={[styles.tileMobile, currentTab === TabTypes.HISTOIRE ? styles.activeTileMobile : ''].join(' ')}>
            <div className={[styles.gridTitleMobile, currentTab === TabTypes.HISTOIRE ? styles.activeTitleMobile : ''].join(' ')}>
              <h3>NOTRE HISTOIRE</h3>
            </div>

            <div className={[styles.gridTextMobile].join(' ')}>
              <p className={styles.gridTextContentMobile}>
              Alt Tab commence dans une chambre d&apos;ado, quand deux frères, Pomf et Thud, décident de commenter en direct des matchs du jeu
              vidéo Starcraft 2. Leur succès grandissant leur ouvre de plus en plus de portes, et agrège autour d&apos;eux toute une communauté de
              ceux qui seront bientôt les superstar du net.
              Dix ans plus tard, Alt Tab est plus que jamais une société innovante, mêlant un dynamisme hors du commun et une expérience unique
              dans nos industries.
              </p>
            </div>
          </div>
          {/*
          NOS VALEURS
          */}
          <div className={[styles.tileMobile, currentTab === TabTypes.VALEURS ? styles.activeTileMobile : ''].join(' ')}>
            <div className={[styles.gridTitleMobile, currentTab === TabTypes.VALEURS ? styles.activeTitleMobile : ''].join(' ')}>
              <h3>NOS VALEURS</h3>
            </div>

            <div className={[styles.gridTextMobile].join(' ')}>
              <p className={styles.gridTextContentMobile}>
              Toutes les personnes travaillant avec nous, en interne ou en externe, doivent adhérer à notre charte de déontologie.
              Par cette charte, nous participons activement à bâtir une industrie du streaming dynamique, pérenne et moderne,
              porteuse de valeurs essentielles, respectant celles et ceux qui sont les fers
               de lance de ces nouvelles cultures.
              </p>
            <p className={styles.downloadButtonP}>
              <a href="http" className={styles.buttonElement}>
                <span className={styles.borderDLButton} />
                <span className={styles.topDLButton} />
                <span className={styles.rightDLButton} />
                <span className={styles.bottomDLButton} />
                <span className={styles.leftDLButton} />
                <a href="/files/Deontologie_AltTab.pdf" className={styles.textDLButton} target="_blank">Télécharger notre charte</a>
              </a>
            </p>
            </div>
          </div>

        </div>
      </div>
  );

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>ATP Vision</title>
        <meta
          name="description"
          content="Alt Tab c'est plus que juste une société de production, c'est un projet commun. Découvrez nos valeurs, nos ambitions et notre charte."
        />
      </Helmet>

      {deviceType === 'desktop'
      && <div className={styles.background2Desktop}>
        <div className={styles.visionSideTitleContainer}>
          <h3 className={styles.visionSideTitle}>
            NOTRE <span>VISION</span>
          </h3>
        </div>
        {showGridContainerDesktop()}
        <div className={styles.footerContainer}>
          <Footer />
        </div>

      </div>
      }
      {deviceType === 'mobile'
      && <div className={styles.background2Mobile}>
        {showGridContainerMobile()}
          <div className={styles.footerContainer}>
            <Footer />
          </div>

      </div>
      }
    </div>
  );
};
export default VisionPage;
