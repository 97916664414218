// components/Image/index.tsx
import {useCallback, useEffect, useState} from 'react';

interface ImageProps {
  src: string;
  placeholderImg: string;
  className: string;
}
export default ({src, placeholderImg, className, ...props}: ImageProps) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);

  const onLoad = useCallback(() => {
    setSrc(src);
  }, [src]);

  useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener('load', onLoad);

    return () => {
      img.removeEventListener('load', onLoad);
    };
  }, [src, onLoad]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <img {...props} alt={imgSrc} src={imgSrc} className={className}/>;
};
