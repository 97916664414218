import {useLocation} from 'react-router-dom';
import {RootState} from 'src/app/rootReducer';
import {Member} from 'src/features/members';
import {useAppSelector} from 'src/hooks/hooks';
import styles from './MemberCard.module.css';

interface MemberCardProps {
  member: Member;
  width: number;
  // currentPage: string;
}

const MemberCard = ({member, width}: MemberCardProps) => {

  const location = useLocation();
  const {deviceType} = useAppSelector((s: RootState) => s.main);

  return (
    <div className={styles.cardContainer}>
      <img src={member.imageUrl} alt="card" className={styles.image} style={{width: `${width}px`, height: `${width}px`}} />
      <div className={styles.infoContainer}>
        <div className={[deviceType === 'desktop' ? styles.position : styles.positionMobile].join(' ')}>
          {member.position === 'PDG' ? member.position : `${member.position} ${member.pole}`}
        </div>
        <div className={[
          location.pathname === '/' ? styles.descriptionForHomepage : '',
          deviceType === 'desktop' && location.pathname === '/members' ? styles.descriptionForMemberspage : '',
          deviceType === 'mobile' && location.pathname === '/members' ? styles.descriptionForMemberspageMobile : '',
        ].join(' ')}>
          {`${member.firstname} ${member.lastname}`}
        </div>
      </div>
    </div>
  );
};
export default MemberCard;
