/* eslint-disable no-underscore-dangle */
import {useEffect} from 'react';
import {loadMembersList} from 'src/features/members';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import MemberCard from '../MemberCard';
import styles from './MembersContainer.module.css';

interface MemberContainerProps {
  homepage: boolean;
}
const MembersContainer = ({homepage}: MemberContainerProps) => {
  const {membersList} = useAppSelector((s) => s.members);
  const {deviceType} = useAppSelector((s) => s.main);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadMembersList());
  }, [dispatch]);

  return (
    <div id="membersContainerID" className={styles.membersContainer}>
      <div className={[styles.sideTitle, homepage ? styles.positionAbsolute : styles.positionFixed].join(' ')}>
        <span className={styles.span}>NOTRE</span> DIRECTION
      </div>
      {/* # # # # # # # # # #
      DESKTOP MEMBERS
      # # # # # # # # # #  */}
      {deviceType === 'desktop'
      && <div className={styles.squadContainerDesktop}>
      {/* FIRST ROW */}
        <div className={[styles.membersFirstRow, styles.squadRow].join(' ')}>
          {membersList.map((member, index) => (
            index <= (deviceType === 'desktop' ? 2 : 2) && (
              <MemberCard member={member} width={200} key={member._id}/>
            )
          ))}
        </div>
      {/* SECOND ROW */}
        <div className={[styles.membersSecondRow, styles.squadRow].join(' ')}>
          {membersList.map((member, index) => (
            // index > 3 && index < 6 && (
            index > (deviceType === 'desktop' ? 2 : 2) && index < 8 && (
              <MemberCard member={member} width={200} key={member._id}/>
            )
          ))}
        </div>
      </div>
      }
      {/* # # # # # # # # # #
      MOBILE MEMBERS
      # # # # # # # # # #  */}
      { deviceType === 'mobile'
      && <div className={styles.squadContainerMobile}>
      {/* FIRST ROW */}
        <div className={[styles.mobileMembersBoard].join(' ')}>
          {membersList.map((member, index) => (
            index <= 8 && (
              <MemberCard member={member} width={200} key={member._id}/>
            )
          ))}
        </div>
      </div>
      }
    </div>
  );
};

export default MembersContainer;
