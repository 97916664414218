export enum TagsTypes {
  All = '',
  Partenariats = 'Partenariats et Sponsoring',
  Agence = 'Agent de Talent',
  Expert = 'Expert en communauté',
  Evenementiel = 'Evenementiel',
  Audiovisuel = 'Audiovisuel ',
  Conception = "Conception d'Histoires Originales",
  Clients = 'Clients',
  PRODUIRE = 'PRODUIRE',
  ENGAGEZ = 'ENGAGEZ VOS CIBLES',

}
