/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import {useNavigate} from 'react-router-dom';
import {Talent} from 'src/features/talents';
import styles from './TalentCard.module.css';

interface TalentCardProps {
  width: number;
  height: number;
  talent: Talent;
  index: number;
  pageAgence?: boolean
}
const TalentCard = ({talent, pageAgence}: TalentCardProps) => {
  const navigate = useNavigate();

  return (
      <div className={styles.cardContainer}>
        <img src={talent.photo} alt="talent" className={styles.image} onClick={() => {
          if (pageAgence) navigate(`/projet/${talent.description.replace(' ', '-')}`);
        }}/>
        <div className={styles.cardContent}>
          <p className={styles.nickname}>
            {talent.firstname}
            {/* &quot;{talent.nickname}&quot; {talent.lastname} */}
          </p>
          <p className={styles.description}>{talent.description}
          {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste voluptatem nemo quod corporis nostrum laboriosam, corrupti reprehenderit aut deleniti. */}
          </p>
          {/* <p className={styles.gameTeam}>
            {talent.nationality} &ndash; {talent.game} &ndash; {talent.teamName}
          </p> */}
        </div>
      </div>
  );
};

export default TalentCard;
