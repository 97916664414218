import {Helmet} from 'react-helmet';
import ContactUsAndLogo from 'src/components/ContactUsAndLogo';
import Footer from 'src/components/Footer';
import MembersContainer from 'src/components/MembersContainer';
import styles from './MembersPage.module.css';

const MembersPage = () => (
  <div className={styles.pageContainer}>

    <Helmet>
      <title>ATP Équipe</title>
      <meta
        name="description"
        content="Une équipe soudée, passionnée et avec beaucoup d'xp, c'est la première force d'Alt Tab dans tous ses projets audiovisuels, découvrez nos profils !"
      />
    </Helmet>
    <MembersContainer homepage={false}/>
    <ContactUsAndLogo noLogo/>
    <div className={styles.footerContainer}>
      <Footer />
    </div>
  </div>
);
export default MembersPage;
