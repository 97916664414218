/* eslint-disable max-len */
import {Helmet} from 'react-helmet';
import Footer from 'src/components/Footer';
import styles from './MentionsLegales.module.css';

const MentionsLegales = () => (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>ATP Mentions Légales</title>
        <meta
          name="description"
          content="Mentions légales site web Alt Tab Productions"
        />
      </Helmet>
    <div className={styles.contentContainer}>
      <h3 className={styles.center}>Mentions legales</h3>
      <h4 className={styles.title}>EDITION DU SITE</h4>

        Le site www.alttabprod.com (ci-après, le « Site ») est édité par Alt Tab Productions, société par actions simplifiée, au capital de 7.192,50 euros, dont le siège social est situé 30 Boulevard Paul Vaillant Couturier 94200 Ivry sur Seine, immatriculée au Registre du Commerce et des Sociétés de Créteil sous le numéro 535 165 013 (ci-après, l&apos;« Éditeur »).
      <br />
      <br />

      L&apos;Éditeur peut être contacté à l&apos;adresse email contact@alttabprod.com.
      <br />
      <br />
        Le numéro de TVA Intracommunautaire de l&apos;Éditeur est le : FR36 535 165 013.
      <br />
      <h4 className={styles.title}>HEBERGEUR DU SITE INTERNET</h4>

      Le Site est hébergé par Netlify, dont le siège social est situé 2325 3rd Street, Suite 296, San Francisco, California 94107.
      <br />
        <br />
      L&apos;hébergeur est joignable à l&apos;adresse email support@netlify.com
      <h4 className={styles.title}>RESPECT DE LA PROPRIETE INTELLECTUELLE</h4>

      Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner le Site et plus généralement tous les éléments reproduits ou utilisés sur le Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.
      <br />
      <br />
      Ils sont la propriété pleine et entière de l&apos;Éditeur ou de ses partenaires, sauf mentions particulières.
      <br />
      <br />
      Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l&apos;accord préalable et écrit de l&apos;Éditeur, sont strictement interdites. Le fait pour l&apos;Éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation des dites utilisations et renonciation aux poursuites.
      <br />
      <br />
      Seule l&apos;utilisation pour un usage privé dans un cercle de famille est autorisée et toute autre utilisation est constitutive de contrefaçon et/ou d&apos;atteinte aux droits voisins, sanctionnées par Code de la propriété intellectuelle. La reprise de tout ou partie de ce contenu nécessite l&apos;autorisation préalable de l&apos;Éditeur ou du titulaire des droits sur ce contenu.

      <h4 className={styles.title}>LIENS HYPERTEXTES</h4>
      Le Site peut contenir des liens hypertextes donnant accès à d&apos;autres sites web édités et gérés par des tiers et non par l&apos;Éditeur. L&apos;Éditeur ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales.
      <br />
      <br />

      La création de liens hypertextes vers le Site ne peut être faite qu&apos;avec l&apos;autorisation écrite et préalable de l&apos;Éditeur.

      <h4 className={styles.title}>COLLECTE DE DONNEES PERSONNELLES</h4>

      Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 modifiée, relative à l&apos;informatique, aux fichiers et aux libertés, l&apos;utilisateur est informé que l&apos;Éditeur procède à la collecte et au traitement de données personnelles, lors de la connexion au Site.
      <br />
      <br />

      Les données collectées servent principalement à permettre la mise à disposition de la plateforme, son optimisation et sa sécurisation afin d&apos;offrir aux utilisateurs un service de la meilleure qualité possible.
      <br />
      <br />

      Des données supplémentaires pourront éventuellement être collectées par l&apos;Éditeur pour permettre la bonne exécution de contrats commerciaux conclus par l&apos;entremise de la plateforme avec l&apos;utilisateur.
      <br />
      <br />

      L&apos;utilisateur est informé qu&apos;il dispose d&apos;un droit d&apos;accès, d&apos;interrogation, de modification et de suppression des informations qui le concerne, à exercer à tout moment auprès de l&apos;Éditeur soit directement sur le Site, soit par courrier postal adressé au 30 Boulevard Paul Vaillant Couturier 94200 Ivry sur Seine, France soit par e-mail à l&apos;adresse contact@alttabprod.com.
      <br />
      <br />

      Les informations recueillies pourront éventuellement être partagées à des tiers résidants dans l&apos;Union Européenne dans les cas suivants :
      <br />
      <br />

      <ul>
        <li>Lorsque l&apos;utilisateur publie, dans une zone de commentaire, des informations accessibles au public;</li>
        <li>Lorsque l&apos;utilisateur y consent expressément;</li>
        <li>Lorsque la loi l&apos;exige ou afin de coopérer à une enquête judiciaire à la demande exprès d&apos;un détenteur de l&apos;autorité publique;</li>
        <li>Pour l&apos;exécution de prestations commerciales pour lesquelles est rendu nécessaire la coopération d&apos;un tiers, notamment dans le cas où l&apos;Éditeur choisirait de sous-traiter certaines de ses prestations par l&apos;entremise de services tiers.</li>
        <li>Lorsque certaines informations sont obligatoires pour accéder à des fonctionnalités spécifiques du Site, l&apos;Éditeur indiquera ce caractère obligatoire au moment de la saisie des données.</li>
      </ul>

      Les données personnelles collectées ne seront conservées que le temps nécessaire pour permettre la bonne utilisation du Site, empêcher les fraudes et abus, et satisfaire aux obligations légales et réglementaires de l&apos;Éditeur concernant la gestion du Site.

    </div>
    <Footer />
    </div>

);

export default MentionsLegales;
