/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-extend-native */
import {Fragment, useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'src/app/rootReducer';
import {Event, loadEventsList} from 'src/features/events';
import {expertiseZoomWasPlayed} from 'src/features/main';
import {TagsTypes} from 'src/utils/tags';
import Scrollbar from 'smooth-scrollbar';
import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im';
import {BsCaretDown, BsFillCaretUpFill} from 'react-icons/bs';
import {GrClose} from 'react-icons/gr';
import EventCard from '../EventCard';
import styles from './Expertise.module.css';
import forwardArrow1 from '../../assets/icons/forward-arrow-1.png';
import ligneSeuleImg from '../../assets/background/LIGNE_V3.png';
import {useAppDispatch} from 'src/hooks/hooks';

const Expertise = () => {
  const dispatch = useAppDispatch();

  const {eventsList} = useSelector((s: RootState) => s.events);
  const {expertiseZoomPlayed, deviceType} = useSelector((s:RootState) => s.main);
  const [ArrowForwardIsFadingOut, setArrowForwardIsFadingOut] = useState(false);
  const [ArrowBackIsFadingOut, setArrowBackIsFadingOut] = useState(false);

  const [filteredEventsList, setFilteredEventslist] = useState<Event[]>(eventsList);
  const [currentTab, setCurrentTab] = useState<TagsTypes | null>();

  const [showMobileFilters, setShowMobileFilters] = useState(false);

  const handleTagsClick = useCallback((filter: TagsTypes) => {
    let newEventsList : Event[];
    if (filter === TagsTypes.ENGAGEZ) {
      newEventsList = eventsList.filter((event) => event.tags.includes(TagsTypes.Agence || TagsTypes.Expert || TagsTypes.Partenariats));
    } else if (filter === TagsTypes.PRODUIRE) {
      newEventsList = eventsList.filter((event) => event.tags.includes(TagsTypes.Audiovisuel || TagsTypes.Evenementiel || TagsTypes.Conception));
    } else {
      newEventsList = (eventsList.filter((e) => e.tags.includes(filter)));
    }
    const sortByDisplayRank = newEventsList.sort((a, b) => a.displayRank - b.displayRank);
    const twentyEvents = sortByDisplayRank.filter((e) => e.displayRank <= 20);

    // Check if filtered array is equal to new filter => render full list
    if (currentTab === filter) {
      setFilteredEventslist(eventsList);
      setCurrentTab(null);
      return;
    }
    setCurrentTab(filter);

    setFilteredEventslist(twentyEvents);
  }, [currentTab, eventsList]);

  useEffect(() => {
    dispatch(loadEventsList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredEventslist(eventsList);
  }, [eventsList]);

  const changeZoomValue = useCallback(() => {
    dispatch(expertiseZoomWasPlayed());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertiseZoomPlayed]);

  // DOM Selectors
  const [cardContainer, setCardContainer] = useState<HTMLElement | null>();
  const [expertiseWrapper, setExpertiseWrapper] = useState<HTMLElement | null>();
  const [expertiseContainer, setExpertiseContainer] = useState<HTMLElement | null>();

  useEffect(() => {
    setCardContainer(document.querySelector(`.${styles.cardContainer}`) as HTMLElement);
    setExpertiseWrapper(document.querySelector(`.${styles.expertiseWrapper}`) as HTMLElement);
    setExpertiseContainer(document.querySelector(`.${styles.expertiseContainer}`) as HTMLElement);
    return () => {
      setCardContainer(null);
      setExpertiseWrapper(null);
      setExpertiseContainer(null);
    };
  }, []);

  // HANDLE ARROWS
  useEffect(() => {
    // make arrowBack visible
    const ArrowBack = document.querySelector(`.${styles.arrowBack}`) as HTMLElement;
    if (ArrowBack && cardContainer) {
      if (cardContainer.scrollLeft > 0) {
        ArrowBack.style.visibility = 'visible';
      }
    }
  });
  useEffect(() => {
    const ArrowBack = document.querySelector(`.${styles.arrowBack}`) as HTMLElement;
    const ArrowForward = document.querySelector(`.${styles.arrowForward}`) as HTMLElement;
    if (ArrowBack && ArrowForward && cardContainer) {
      // CardContainer inner width
      // Arrow Forward behavior
      ArrowForward.addEventListener('click', () => {
        const ccWidth = cardContainer.childElementCount * 200;
        const innerPos = cardContainer.scrollLeft + cardContainer.offsetWidth;
        // increment scroll
        cardContainer.scrollLeft += 260;
        ArrowBack.style.visibility = 'visible';
        //
        // const w = cardContainer.offsetWidth
        // const s = cardContainer.scrollLeft
        // if we get close to the end
        // get cardContainer full width without padding
        if (innerPos >= ccWidth - 399) {
          // when reach end of cardContainer : hide forward arrow
          setArrowForwardIsFadingOut(true);
          setTimeout(() => {
            setArrowForwardIsFadingOut(false);
            ArrowForward.style.visibility = 'hidden';
          }, 400);
        }
        // console.log(innerDimensions(cardContainer))
      });
      // Arrow Back behavior
      ArrowBack.addEventListener('click', () => {
        const ccWidth = cardContainer.childElementCount * 200;
        const innerPos = cardContainer.scrollLeft + cardContainer.offsetWidth;
        cardContainer.scrollLeft -= 260;
        if (cardContainer.scrollLeft < 140) {
          setArrowBackIsFadingOut(true);
          setTimeout(() => {
            setArrowBackIsFadingOut(false);
            ArrowBack.style.visibility = 'hidden';
          }, 400);
        }
        // make ArrowForward visible again
        if (innerPos < ccWidth) {
          ArrowForward.style.visibility = 'visible';
        }
      });
    }
  });

  // Couche + sourcouche
  useEffect(() => {
    const heroVideoContainer = document.querySelector('#heroVideoContainerID') as HTMLElement;
    const scrollContainer = document.querySelector('#parallaxContainerID') as HTMLElement;
    //

    const allTargets = Scrollbar.getAll();
    const target = allTargets[0];

    // handle positions on scroll
    if (target && expertiseContainer && heroVideoContainer && scrollContainer) {
      // get initial elements offset
      const videoHeight = heroVideoContainer.offsetHeight;
      const parallaxContainerHeight = scrollContainer.offsetHeight;
      const expertiseContainerHeight = expertiseContainer.offsetHeight;

      const total = videoHeight + parallaxContainerHeight + expertiseContainerHeight;

      // handle positions on scroll
      target.addListener((e) => {
        const scrollPos = e.offset.y;
        // console.log(total - scrollPos - window.innerHeight)

        if (scrollPos < videoHeight + parallaxContainerHeight) {
          expertiseContainer.style.top = `${0}px`;
          expertiseContainer.style.bottom = '';
        } else if (scrollPos > videoHeight + parallaxContainerHeight && scrollPos < total) {
          const v = scrollPos - videoHeight - parallaxContainerHeight;
          expertiseContainer.style.top = `${v}px`;
          expertiseContainer.style.bottom = '';
          expertiseContainer.style.display = 'block';
        } else if (scrollPos > (total - window.innerHeight)) {
          expertiseContainer.style.top = '';
          expertiseContainer.style.bottom = `${0}px`;
          expertiseContainer.style.display = 'block';
        }
      });
    }
  });
  // Positions of expertiseWrapper
  useEffect(() => {
    if (expertiseContainer && expertiseWrapper) {
      window.addEventListener('scroll', () => {
        if (expertiseWrapper.getBoundingClientRect().top > 0) {
          // STATE n°1
          expertiseContainer.style.position = 'absolute';
          expertiseContainer.style.top = '0';
          expertiseContainer.style.bottom = '';
        }
        if (expertiseWrapper.getBoundingClientRect().top < 0 && expertiseWrapper.getBoundingClientRect().bottom - window.innerHeight > 0) {
          // STATE n°2
          expertiseContainer.style.position = 'fixed';
          expertiseContainer.style.top = '0';
          expertiseContainer.style.bottom = '';
        }
        if (expertiseWrapper.getBoundingClientRect().bottom - window.innerHeight < 0) {
          // STATE n°3
          expertiseContainer.style.position = 'absolute';
          expertiseContainer.style.top = '';
          expertiseContainer.style.bottom = '0';
        }
      });
    }
  }, [expertiseContainer, expertiseWrapper]);

  const handleOpenMobileFilters = () => {
    const filtersSections = document.querySelector(`.${styles.filtersSections}`) as HTMLElement;
    const html = document.querySelector('#c54v') as HTMLElement;
    if (filtersSections && html) {
      if (showMobileFilters === false) {
        html.style.overflowY = 'hidden';
        setShowMobileFilters(true);
        filtersSections.animate([
          {maxHeight: '0vh', opacity: 0.3}, {maxHeight: '100vh', opacity: 1},
        ], {duration: 1400, easing: 'cubic-bezier(.17,.67,.59,1.3)'});
        filtersSections.style.maxHeight = '100vh';
      }
      if (showMobileFilters === true) {
        html.style.overflowY = 'auto';
        setShowMobileFilters(false);
        // filtersSections.animate([
        //   { maxHeight: "100vh" }, { maxHeight: "0vh" },
        // ], 400)
        filtersSections.style.maxHeight = '0vh';
      }
    }

    // Hide navbar button when filters open
    const mobileNavButton = document.querySelector('#mobileNavButton') as HTMLElement;
    if (mobileNavButton) {
      console.log(mobileNavButton);
      if (!showMobileFilters) {
        mobileNavButton.style.display = 'none';
      } else {
        mobileNavButton.style.display = 'flex';
      }
    }
  };

  const showMobileExpertise = () => (
    <div className={styles.expertiseMobileContainer} id="expertiseContainerID">

      {showMobileFilters
        && <div className={styles.filtersVeil}
        onClick={() => {
          handleOpenMobileFilters();
        }}
        />
      }
      <div className={styles.mobilePageTitle}>
        <p className={styles.pageTitleMobile}>L&apos;EXPERTISE <span className={styles.mobileSpan}>ALT TAB</span></p>
      </div>
      <div className={styles.mobileSlider}>
        <div className={styles.mobileFilters}>
          <div className={styles.mobileOpenFiltersButton}
          // onClick={(e:any) => { openFiltersMenu(e) }}
          onClick={(e:any) => { handleOpenMobileFilters(); }}
          >
            {/* <VscSettings /> */}
            <div className={styles.mobileFilterButtonTitleContainer}>
              <p className={styles.mobileFilterButtonTitle}>
                <span style={{color: '#4a4a4a'}}>
                {filteredEventsList && `(${filteredEventsList.length})`}
                </span>
                &nbsp; Filtrer par catégorie</p>
              <div className={styles.mobileFilterButtonArrowDown}>
                {!showMobileFilters ? <BsCaretDown /> : <BsFillCaretUpFill />}
              </div>
            </div>
            <div className={styles.closeFiltersButton}>
              {showMobileFilters
              && <span className={styles.closeFiltersButtonIcon}><GrClose /></span>
              }
            </div>
          </div>
          <div className={styles.mobileFiltersContent}>
            <div className={styles.filtersSections} style={{maxHeight: '0vh'}}>
              <div className={[styles.filtersMobileTop,
                (currentTab === TagsTypes.Expert || currentTab === TagsTypes.Partenariats || currentTab === TagsTypes.Agence) ? styles.lightBackground : ''].join(' ')}>
                <div className={styles.mobileFilterTabTitleContainer}>
                  <p className={[(currentTab === TagsTypes.Expert || currentTab === TagsTypes.Partenariats || currentTab === TagsTypes.Agence) ? styles.activeMobileTab : '',
                    styles.mobileFilterTabTitle,
                  ].join(' ')}>
                    Communiquer
                  </p>
                </div>
                <div className={styles.mobileFilterRow}>
                  <div className={styles.mobileFilterTabContainer}>
                    <div className={[styles.mobileFilterTabLine, currentTab === TagsTypes.Agence && styles.activeMobileTabLine].join(' ')}>
                      <div className={styles.mobileFilterTabHalfLine} />
                    </div>
                    <div onClick={() => handleTagsClick(TagsTypes.Agence)} className={styles.mobileFilterCheckBoxContainer}>
                      <p className={[styles.mobileFilterCheckBox, styles.activeMobileCheckBox].join(' ')}>
                        {currentTab === TagsTypes.Agence
                          ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                      </p>
                    </div>
                    <p
                    role="none"
                    onClick={() => handleTagsClick(TagsTypes.Agence)}
                    className={[currentTab === TagsTypes.Agence ? styles.activeMobileTab : '', styles.mobileFilterTab].join(' ')}
                    >Agence de talents</p>
                  </div>

                  <div className={styles.mobileFilterTabContainer}>
                    <div className={[styles.mobileFilterTabLine, currentTab === TagsTypes.Partenariats && styles.activeMobileTabLine].join(' ')}>
                      <div className={styles.mobileFilterTabHalfLine} />
                    </div>
                    <div onClick={() => handleTagsClick(TagsTypes.Partenariats)} className={styles.mobileFilterCheckBoxContainer}>
                      <p className={[styles.mobileFilterCheckBox, styles.activeMobileCheckBox].join(' ')}>
                        {currentTab === TagsTypes.Partenariats
                          ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                      </p>
                    </div>
                    <p
                    role="none"
                    onClick={() => handleTagsClick(TagsTypes.Partenariats)}
                    className={[currentTab === TagsTypes.Partenariats ? styles.activeMobileTab : '', styles.mobileFilterTab].join(' ')}
                    >Partenariats & sponsoring</p>
                  </div>

                  <div className={styles.mobileFilterTabContainer}>
                    <div className={[styles.mobileFilterTabLine, currentTab === TagsTypes.Expert && styles.activeMobileTabLine].join(' ')}>
                      <div className={styles.mobileFilterTabHalfLine} />
                    </div>
                    <div onClick={() => handleTagsClick(TagsTypes.Expert)} className={styles.mobileFilterCheckBoxContainer}>
                      <p className={[styles.mobileFilterCheckBox, styles.activeMobileCheckBox].join(' ')}>
                        {currentTab === TagsTypes.Expert
                          ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                      </p>
                    </div>
                    <p
                    role="none"
                    onClick={() => handleTagsClick(TagsTypes.Expert)}
                    className={[currentTab === TagsTypes.Expert ? styles.activeMobileTab : '', styles.mobileFilterTab].join(' ')}
                    >Expert en communauté</p>
                  </div>
                </div>
              </div>
              <div className={[styles.filtersMobileBottom,
                (currentTab === TagsTypes.Conception || currentTab === TagsTypes.Audiovisuel || currentTab === TagsTypes.Evenementiel) ? styles.lightBackground : ''].join(' ')}>
                <div className={styles.mobileFilterTabTitleContainer}>
                  <p className={[(currentTab === TagsTypes.Conception || currentTab === TagsTypes.Audiovisuel || currentTab === TagsTypes.Evenementiel) ? styles.activeMobileTab : '',
                    styles.mobileFilterTabTitle,
                  ].join(' ')}>
                    Produire
                  </p>
                </div>
                <div className={styles.mobileFilterRow}>
                  <div className={styles.mobileFilterTabContainer}>
                    <div className={[styles.mobileFilterTabLine, currentTab === TagsTypes.Evenementiel && styles.activeMobileTabLine].join(' ')}>
                      <div className={styles.mobileFilterTabHalfLine} />
                    </div>
                    <div onClick={() => handleTagsClick(TagsTypes.Evenementiel)} className={styles.mobileFilterCheckBoxContainer}>
                      <p className={[styles.mobileFilterCheckBox, styles.activeMobileCheckBox].join(' ')}>
                        {currentTab === TagsTypes.Evenementiel
                          ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                      </p>
                    </div>
                    <p
                    role="none"
                    onClick={() => handleTagsClick(TagsTypes.Evenementiel)}
                    className={[currentTab === TagsTypes.Evenementiel ? styles.activeMobileTab : '', styles.mobileFilterTab].join(' ')}
                    >Événementiel</p>
                  </div>

                  <div className={styles.mobileFilterTabContainer}>
                    <div className={[styles.mobileFilterTabLine, currentTab === TagsTypes.Audiovisuel && styles.activeMobileTabLine].join(' ')}>
                      <div className={styles.mobileFilterTabHalfLine} />
                    </div>
                    <div onClick={() => handleTagsClick(TagsTypes.Audiovisuel)} className={styles.mobileFilterCheckBoxContainer}>
                      <p className={[styles.mobileFilterCheckBox, styles.activeMobileCheckBox].join(' ')}>
                        {currentTab === TagsTypes.Audiovisuel
                          ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                      </p>
                    </div>
                    <p
                    role="none"
                    onClick={() => handleTagsClick(TagsTypes.Audiovisuel)}
                    className={[currentTab === TagsTypes.Audiovisuel ? styles.activeMobileTab : '', styles.mobileFilterTab].join(' ')}
                    >Audiovisuel</p>
                  </div>

                  <div className={styles.mobileFilterTabContainer}>
                    <div className={[styles.mobileFilterTabLine, currentTab === TagsTypes.Conception && styles.activeMobileTabLine].join(' ')}>
                      <div className={styles.mobileFilterTabHalfLine} />
                    </div>
                    <div onClick={() => handleTagsClick(TagsTypes.Conception)} className={styles.mobileFilterCheckBoxContainer}>
                      <p className={[styles.mobileFilterCheckBox, styles.activeMobileCheckBox].join(' ')}>
                        {currentTab === TagsTypes.Conception
                          ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                      </p>
                    </div>
                    <p
                    role="none"
                    onClick={() => handleTagsClick(TagsTypes.Conception)}
                    className={[currentTab === TagsTypes.Conception ? styles.activeMobileTab : '', styles.mobileFilterTab].join(' ')}
                    >Conception d&apos;histoires</p>
                  </div>
                </div>
              </div>
              <span className={styles.bottomLine} />
            </div>
          </div>
        </div>
        <div className={styles.mobileEvents}>
          <div className={styles.mobileEventsTopRow}>
            {!!filteredEventsList
              && filteredEventsList.map((e: Event, i:number) => (
                (i % 2 === 0)
                && <div className={[styles.mobileThumbnail].join(' ')}>
                  <EventCard event={e} width={200} />
                </div>
              ))
            }
          </div>
          <div className={styles.mobileEventsBottomRow}>
            {!!filteredEventsList
              && filteredEventsList.map((e: Event, i:number) => (
                (i % 2 === 1)
                && <div className={[styles.mobileThumbnail].join(' ')}>
                  <EventCard event={e} width={200} />
              </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={[
      styles.expertiseWrapper,
      deviceType === 'mobile' && styles.mobileExpertiseWrapper,
    ].join(' ')}
    >
      {/* # # # # # # # # # #
      DESKTOP EXPERTISE
      # # # # # # # # # #  */}
      {deviceType === 'desktop'
        ? <div
        id="expertiseContainerID"
        className={styles.expertiseContainer}
      >
        <div className={styles.pageTitle}>
          L&apos;EXPERTISE <span className={styles.span}>ALT TAB</span>
        </div>
        <div className={styles.tabContainer} >
          <div className={styles.tabsMidRow}>
            <p
              onClick={() => handleTagsClick(TagsTypes.ENGAGEZ)}
              className={(currentTab === TagsTypes.ENGAGEZ) ? styles.activeTab : ''}
            >
                Engagez vos cibles
            </p>
            <p
              onClick={() => handleTagsClick(TagsTypes.PRODUIRE)}
              className={(currentTab === TagsTypes.PRODUIRE) ? styles.activeTab : ''}
            >
              Produire
            </p>
          </div>
        </div>
        <div className={styles.surcoucheContainer}>
          <img className={styles.ligneSeuleImg} src={ligneSeuleImg} alt="ligne seule" />
          {/* <img className={styles.surcouche} src={surcoucheImg} alt="surcouche" /> */}
          <div className={styles.surcoucheBg} />
        </div>
        <div className={styles.sliderContainer}>
          <div className={styles.slider}>
            {
              filteredEventsList.length > 7
              && <div className={styles.arrowsContainer}>
              <span
                className={[
                  styles.arrow,
                  styles.arrowBack,
                  ArrowBackIsFadingOut ? styles.arrowBackFadeOut : '',
                ].join(' ')}
                onMouseEnter={() => {
                  const ArrowBackIcon = document.querySelector(`.${styles.arrowBackIcon}`) as HTMLElement;
                  if (ArrowBackIcon) {
                    ArrowBackIcon.animate(
                      [{width: '3rem'}, {width: '3.5rem'}],
                      300,
                    );
                    ArrowBackIcon.style.width = '3.5rem';
                  }
                }}
                onMouseLeave={() => {
                  const ArrowBackIcon = document.querySelector(`.${styles.arrowBackIcon}`) as HTMLElement;
                  if (ArrowBackIcon) {
                    ArrowBackIcon.animate(
                      [{width: '3.5rem'}, {width: '3rem'}],
                      300,
                    );
                    ArrowBackIcon.style.width = '3rem';
                  }
                }}
              >
                <img
                  className={styles.arrowBackIcon}
                  src={forwardArrow1}
                  alt="backArrow1"
                />
              </span>
              <span
                className={[styles.arrow,
                  styles.arrowForward,
                  ArrowForwardIsFadingOut ? styles.arrowForwardFadeOut : '',
                ].join(' ')}
                onMouseEnter={() => {
                  const ArrowForwardIcon = document.querySelector(`.${styles.arrowForwardIcon}`) as HTMLElement;
                  if (ArrowForwardIcon) {
                    ArrowForwardIcon.animate(
                      [{width: '3rem'}, {width: '3.5rem'}],
                      300,
                    );
                    ArrowForwardIcon.style.width = '3.5rem';
                  }
                }}
                onMouseLeave={() => {
                  const ArrowForwardIcon = document.querySelector(`.${styles.arrowForwardIcon}`) as HTMLElement;
                  if (ArrowForwardIcon) {
                    ArrowForwardIcon.animate(
                      [{width: '3.5rem'}, {width: '3rem'}],
                      300,
                    );
                    ArrowForwardIcon.style.width = '3rem';
                  }
                }}
              >
                <img
                  className={styles.arrowForwardIcon}
                  src={forwardArrow1}
                  alt="forwardArrow1"
                />
              </span>
            </div>
            }
            <div className={`${styles.cardContainer} d-flex`}>

            {!!filteredEventsList
              && filteredEventsList.map((e: Event) => (
                <Fragment key={e.id}>
                  <EventCard event={e} width={200} />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      /* # # # # # # # # # # # #
      MOBILE EXPERTISE
      # # # # # # # # # # # #  */
        : deviceType === 'mobile' && showMobileExpertise()
      /* # # # # # # # # # # # #
      END OF MOBILE EXPERTISE
      # # # # # # # # # # # #  */
      }

    </div>
  );
};

export default Expertise;
