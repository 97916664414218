/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-case */
import {useState, useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {loadLocations, unloadLocations} from 'src/features/main';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'src/app/rootReducer';
import Scrollbar from 'smooth-scrollbar';
import NavLogo from '../../assets/logo/logo_vertical_simple_blanc.png';
import mobileMenuIcon from '../../assets/icons/mobile-menu.svg';
import carrerManagementImg from '../../assets/background/carrer-management.jpg';
import contentCreationImg from '../../assets/background/content-creation.jpg';
import imageDevelopmentImg from '../../assets/background/image-development.jpg';
import altTabNetworkImg from '../../assets/background/alt-tab-network.jpg';
import performanceCenterImg from '../../assets/background/performance-center.jpg';
import styles from './Navbar.module.css';
import {useAppDispatch} from 'src/hooks/hooks';

const NavData = [
  {
    title: 'Réalisations',
    url: 'realisations',
    id: 0,
    navBg: carrerManagementImg,
  },
  {
    title: 'Média',
    url: 'media',
    id: 1,
    navBg: contentCreationImg,
  },
  {
    title: 'Agence',
    url: 'agence',
    id: 2,
    navBg: imageDevelopmentImg,
  },
  {
    title: 'Logo',
    logoUrl: NavLogo,
    url: '/',
    id: 3,
  },
  {
    title: 'Équipe',
    url: 'members',
    id: 4,
    navBg: altTabNetworkImg,
  },
  {
    title: 'Contact',
    url: 'contact',
    id: 5,
    navBg: carrerManagementImg,
  },
  {
    title: 'Vision',
    url: 'vision',
    id: 6,
    navBg: performanceCenterImg,
  },
];

const Navbar = () => {
  const dispatch = useAppDispatch();
  const [currentTab, setCurrentTab] = useState<number>();
  const {pathname} = useLocation();
  const [NavItems, setNavItems] = useState(NavData);

  const navbar = document.querySelector(`.${styles.navbarContainer}`) as HTMLElement;

  // Mobile nav settings
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState<boolean>(false);
  const [mobileNavButtonIsVisible, setMobileNavButtonIsVisible] = useState(0);

  // Scroll settings
  const [currentPos, setCurrentPos] = useState(0);
  const [currentMouseY, setCurrentMouseY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('');

  // Assisted scroll selectors (DOM , locations)
  const [locations, setLocations] = useState<number[]>();

  const allTargets = Scrollbar.getAll();
  const target = allTargets[0];

  const {deviceType} = useSelector((s:RootState) => s.main);
  const trash = () => {
  // #########################################
  // ### LOCATIONS FOR ASSISTED SCROLLING ###
  // #########################################
  // useEffect(() => {
  //   if (pathname === "/") {
  //     setDOM(
  //       [
  //         document.querySelector('#heroVideoContainerID') as HTMLElement,
  //         document.querySelector('#parallaxContainerID') as HTMLElement,
  //         document.querySelector('#expertiseContainerID') as HTMLElement,
  //         document.querySelector('#partenairesContainerID') as HTMLElement,
  //         document.querySelector('#membersContainerID') as HTMLElement,
  //         document.querySelector('#contactUsFooterID') as HTMLElement,
  //       ]
  //     )
  //   }
  // }, [pathname])

    // useEffect(() => {
    //   if (deviceType === "desktop") {
    //     if (DOM) {
    //       setLocations([
    //         // 0 : heroVideo top
    //         Math.round(DOM[0].getBoundingClientRect().top),
    //         // 1 : parallax top
    //         Math.round(DOM[1].getBoundingClientRect().top),
    //         // 2 : parallax vers le bas
    //         Math.round(DOM[1].getBoundingClientRect().bottom - window.innerHeight),
    //         // 3 : expertise top
    //         Math.round(DOM[2].getBoundingClientRect().top),
    //         // // : expertise vers le milieu
    //         // Math.round(DOM[2].getBoundingClientRect().bottom + (window.innerHeight / 2)),
    //         // 4 : partenaires top
    //         Math.round(DOM[3].getBoundingClientRect().top),
    //         // 5 : partenaires milieu
    //         Math.round(DOM[3].getBoundingClientRect().top + (DOM[3].offsetHeight / 2)),
    //         // 6: squad top
    //         Math.round(DOM[4].getBoundingClientRect().top),
    //         // 7: contact top
    //         Math.round(DOM[5].getBoundingClientRect().top)
    //       ])
    //     }
    //     window.addEventListener('resize', () => {
    //       setLocations([])
    //       if (DOM) {
    //         setLocations([
    //           // 0 : heroVideo top
    //           Math.round(DOM[0].getBoundingClientRect().top),
    //           // 1 : parallax top
    //           Math.round(DOM[1].getBoundingClientRect().top),
    //           // 2 : parallax vers le bas
    //           Math.round(DOM[1].getBoundingClientRect().bottom - window.innerHeight),
    //           // 3 : expertise top
    //           Math.round(DOM[2].getBoundingClientRect().top),
    //           // // : expertise vers le milieu
    //           // Math.round(DOM[2].getBoundingClientRect().bottom + (window.innerHeight / 2)),
    //           // 4 : partenaires top
    //           Math.round(DOM[3].getBoundingClientRect().top),
    //           // 5 : partenaires milieu
    //           Math.round(DOM[3].getBoundingClientRect().top + (DOM[3].offsetHeight / 2)),
    //           // 6: squad top
    //           Math.round(DOM[4].getBoundingClientRect().top),
    //           // 7: contact top
    //           Math.round(DOM[5].getBoundingClientRect().top)
    //         ])
    //       }
    //     });
    //   }
    // }, [DOM, deviceType])

  // #########################################
  // #########################################
  };
  useEffect(() => {
    if (locations) {
      dispatch(loadLocations(locations));
    }
  }, [dispatch, locations]);

  // Detect current page in nav, make the navitem active
  useEffect(() => {
    if (pathname === '/') {
      setCurrentTab(3);
    } else if (pathname !== '/') {
      NavItems.forEach((item) => {
        if (pathname === `/${item.url}`) {
          setCurrentTab(item.id);
        }
      });
    }
  }, [NavItems, pathname]);
  const handleNavTabClick = (item: any) => {
    setCurrentTab(item.id);
    dispatch(unloadLocations());
  };

  const handleCloseNavbar = (mobileNavbar:any, html:any) => {
    if (deviceType === 'mobile') {
      // Animate navbar
      mobileNavbar.animate(
        [{transform: 'translateX(0%)', opacity: 1}, {transform: 'translateX(100%)', opacity: 0}],
        {easing: 'ease-in-out', duration: 500},
      );
      // eslint-disable-next-line no-param-reassign
      html.style.overflowY = 'auto';
      // eslint-disable-next-line no-param-reassign
      mobileNavbar.style.transform = 'translateX(100%)';
      // eslint-disable-next-line no-param-reassign
      mobileNavbar.style.opacity = '0';

      // Change hook state
      setMobileNavIsOpen(false);
    }
  };

  const handleMobileNavbarButton = () => {
    if (deviceType === 'mobile') {
      const mobileNavbar = document.querySelector(`.${styles.navbarMobileContainer}`) as HTMLElement;
      const html = document.querySelector('#c54v') as HTMLElement;
      if (mobileNavbar && html) {
        html.style.overflowY = 'hidden';
        if (!mobileNavIsOpen) {
          // Animate navbar
          mobileNavbar.animate(
            [{transform: 'translateX(100%)', opacity: 0}, {transform: 'translateX(0%)', opacity: 1}],
            {easing: 'ease-in-out', duration: 500},
          );
          mobileNavbar.style.transform = 'translateX(0%)';
          mobileNavbar.style.opacity = '1';

          // Change hook state
          setMobileNavIsOpen(true);
          window.history.pushState({id: 1}, '', '/mmmobileNavvv');
        } else if (mobileNavIsOpen) {
          // Close menu if popstate back
          window.addEventListener('popstate', () => {
            handleCloseNavbar(mobileNavbar, html);
            return null;
          });

          handleCloseNavbar(mobileNavbar, html);
        }
      }
    }
  };
  //
  const scrollToTopOfPage = () => {
    // const topAnchor = document.querySelector('#topAnchor')
    // if (topAnchor !== null) {
    //   topAnchor.scrollIntoView({ behavior: 'smooth' })
    // }
    if (window.scrollY > 0 && target) {
      target.scrollTo(-100, -100, -100, {
        callback: () => console.log('done!'),
      });
    }
  };

  // Navbar pops when mouse gets on top of the screen
  useEffect(() => {
    if (navbar && (pathname === '/')) {
      document.onmousemove = (e) => {
        if (e.clientY < navbar.scrollHeight && window.scrollY > 91) {
          navbar.style.position = 'fixed';
          navbar.style.top = '0';
          navbar.style.zIndex = '24';
          if (window.scrollY > 0) {
            navbar.style.background = '#0a0a0a';
          }
        } else {
          navbar.style.position = 'absolute';
          navbar.style.background = 'transparent';
          navbar.style.zIndex = '20';
        }
      };
    }
  });

  // GET CURRENT SCROLL POSITION
  useEffect(() => {
    if (target) {
      target.addListener((scrollEvent) => {
        setCurrentPos(scrollEvent.offset.y);
      });
    }
  }, [target]);

  // GET CURRENT MOUSE POSITION
  useEffect(() => {
    window.addEventListener('mousemove', (e:any) => {
      setCurrentMouseY(e.clientY);
    });
  }, []);

  // DETECT TARGET SCROLL DIRECTION
  useEffect(() => {
    if (target) {
      let lastOffset:any = null;

      target.addListener(({offset}:any) => {
        if (!lastOffset) {
          lastOffset = offset;
          return;
        }

        const dir = [];

        if (offset.y < lastOffset.y) {
          dir[0] = 'up';
        } else if (offset.y > lastOffset.y) {
          dir[0] = 'down';
        } else {
          dir[0] = 'still';
        }

        if (offset.x < lastOffset.x) {
          dir[1] = 'left';
        } else if (offset.x > lastOffset.x) {
          dir[1] = 'right';
        } else {
          dir[1] = 'still';
        }

        lastOffset = offset;

        switch (dir[0]) {
          case 'up':
          case 'down':
          case 'still':
        }

        switch (dir[1]) {
          case 'left':
          case 'right':
          case 'still':
        }
        setScrollDirection(dir[0]);
      });
    }
  }, [target]);

  // SET NAVBAR INITIAL POSITION
  useEffect(() => {
    if (navbar) {
      navbar.style.top = '0px';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SET NAVBAR NEW POSITION
  useEffect(() => {
    if (navbar && deviceType === 'desktop') {
      if (target) {
        if (currentMouseY < 90) {
          navbar.style.top = `${currentPos}px`;
        } else {
          navbar.style.top = '0px';
          navbar.style.background = 'transparent';
        }
      }
    }
  });

  // NAVBAR onmouseenter
  useEffect(() => {
    if (navbar) {
      navbar.addEventListener('mouseenter', () => {
        navbar.animate([
          {opacity: 0},
          {opacity: 1},
        ], 300);
        navbar.style.background = '#0a0a0a';
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on scrolltop show mobile nav button
  useEffect(() => {
    if (deviceType === 'mobile') {
      const mobileNavbarButton = document.querySelector(`.${styles.mobileNavbarButton}`) as HTMLElement;
      // Register touchstart and touchend listeners for element 'source'
      const html = document.querySelector('#c54v') as HTMLElement;

      // document.addEventListener('touchstart', handleTouchStart, false);
      // document.addEventListener('touchmove', handleTouchMove, false);
      let lastScrollTop = window.pageYOffset || 0;

      window.addEventListener('scroll', (e:any) => {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          // downscroll code
          setMobileNavButtonIsVisible(0);
          if (mobileNavbarButton && window.scrollY > 100 && mobileNavButtonIsVisible === 1) {
            mobileNavbarButton.style.position = 'absolute';
          }
        } else {
          // upscroll code
          if (mobileNavButtonIsVisible === 0) { setMobileNavButtonIsVisible(1); }
          if (mobileNavbarButton && mobileNavButtonIsVisible === 1) {
            mobileNavbarButton.style.position = 'fixed';
          }
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      }, false);
    }
  }, [deviceType, mobileNavButtonIsVisible]);

  return (
    // # # # # # # # # # #
    // DESKTOP NAV
    // # # # # # # # # # #
    <>
    {deviceType === 'desktop'
      && <nav
      id="topnav"
      className={[styles.navbarContainer,
        // stickyNavDesktop ? styles.stickyNavDesktop : "",
      ].join(' ')}
        >
        {NavItems.map((item) => (
          // IF CLICK ON LOGO
          item.title === 'Logo' ? (
            <NavLink
              key={item.id}
              to={`${item.url}`}
              className={[styles.logo, styles.link].join(' ')}
              onClick={() => {
                // const html = document.querySelector('html') as HTMLElement;
                // if (html) {
                //   html.style.overflowY = "hidden"
                // }
                scrollToTopOfPage();
                const navlinkItem = document.querySelectorAll(`.${styles.link}`);
                if (navlinkItem !== null) {
                  navlinkItem.forEach((navlinkElement:any) => {
                    navlinkElement.classList.remove(styles.active);
                  });
                }
                if (locations) {
                  dispatch(loadLocations(locations));
                }
              }}
            >
              <img src={item.logoUrl} alt={item.title} />
            </NavLink>
          ) : (
            // IF CLICK ON ANY OTHER LINK
            <NavLink
              key={item.id}
              to={`/${item.url}`}
              className={[currentTab === item.id ? styles.active : '', styles.link].join(' ')}
              onClick={() => { handleNavTabClick(item); }}
            >
              <p>{item.title}</p>
            </NavLink>
          )))}
      </nav>
    }

    {/* MOBILE NAV */}
    {deviceType === 'mobile'
      && <div className={styles.mobileNav}>
        <div
        aria-hidden="true"
        onClick={() => { handleMobileNavbarButton(); }}
        className={[styles.mobileNavbarButton, mobileNavButtonIsVisible ? styles.stickyMobileNavButton : ''].join(' ')}
        id = "mobileNavButton"
        >
          {/* <GiHamburgerMenu /> */}
          <img src={mobileMenuIcon} alt="mobile-menu" width="50" height="50" />
        </div>
        <nav className={styles.navbarMobileContainer}>
          <NavLink className={styles.mobileNavLogoContainer} to="/">
            <img
            aria-hidden="true"
            className={[styles.logo, styles.mobileNavLogo].join(' ')} src={NavLogo} alt="Alt Tab logo"
            onClick={() => { handleMobileNavbarButton(); }}
            />
          </NavLink>
          {NavItems.map((item, index) => ((
            // IF CLICK ON ANY OTHER LINK
            item.id !== 3
              && <NavLink
                key={item.id}
                to={`/${item.url}`}
                className={[currentTab === item.id ? styles.active : '', styles.mobileNavLink].join(' ')}
                onClick={() => { handleNavTabClick(item); handleMobileNavbarButton(); }}
                // style={{ background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),    url(${item.navBg})` }}
              >
                <p className={styles.navElement}>{item.title}</p>
              </NavLink>
          )))}
        </nav>
      </div>
    }
    </>
  );
};

export default Navbar;
