import {
  FaTwitter,
  FaLinkedinIn,
} from 'react-icons/fa';
import {BsEnvelope} from 'react-icons/bs';
import Footer from '../Footer';
import styles from './ContactUs.module.css';
import ContactUsAndLogo from '../ContactUsAndLogo';

const ContactUs = () => {
  const iconList = [
    {
      name: 'Twitter',
      url: <FaTwitter />,
      link: 'https://twitter.com/Alt_Tab_Prod',
    },

    {
      name: 'Mail',
      url: <BsEnvelope />,
      link: 'mailto:contact@alttabprod.com',
    },

    {
      name: 'Linkedin',
      url: <FaLinkedinIn />,
      link: 'https://fr.linkedin.com/company/alt-tab-prod',
    },
  ];

  return (
    <div id="contactUsFooterID" className={styles.scrollContainer}>
      <div className={styles.contactContainer}>
        <ContactUsAndLogo />
        <div className={styles.ContactUsIconsContainer}>
          {iconList.map((icon) => (
              <div className={`iconsHover ${styles.ContactUsIcons}`} key={icon.name}>
                <a href={icon.link} target="_blank" rel="noopener noreferrer">
                  {icon.url}
                </a>
              </div>
          ))}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
