import {useState, ChangeEvent} from 'react';
import {InlineWidget} from 'react-calendly';
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';
import Footer from 'src/components/Footer';
import {AlertTypes, createAlert} from 'src/features/alert';
import styles from './ContactPage.module.css';
import arrowCorner from '../../assets/icons/arrow-corner.svg';
import arrowLogo from '../../assets/icons/arrow-logo.svg';
import {Helmet} from 'react-helmet';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const baseApiUrl = process.env.REACT_APP_BASE_URL;

enum TabTypes {
  RDV = 'Prendre rendez-vous',
  ECRIRE = 'Nous écrire',
}

const selectorItems = [
  {
    name: 'Vous proposer une collaboration',
    value: 'zacharie.bouhaya@alttabprod.com',
  },
  {
    name: 'Vous proposer un produit',
    value: 'anais.fallet@alttabprod.com',
  },
  {
    name: 'Vous faire parvenir une candidature',
    value: 'thibaut.cambon@alttabprod.com',
  },
  {
    name: 'Vous poser une autre question',
    value: 'sarah.meghar@alttabprod.com',
  },
];
const ContactUs = () => {
  const {deviceType} = useAppSelector((s) => s.main);
  const [selectedTab, setSelectedTab] = useState(TabTypes.ECRIRE);
  const dispatch = useAppDispatch();
  const [showSelectorOptions, setShowSelectorOptions] = useState(false);
  const [sendToMail, setSendToMail] = useState('');
  const [sendToName, setSendToName] = useState('');
  const [contactFormData, setContactFormData] = useState({
    name: null,
    objet: null,
    mail: null,
    phone: null,
    message: null,
    target: null,
  });

  const handleFormChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContactFormData({...contactFormData, [e.target.name]: e.target.value});
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const body = {
      ...contactFormData,
      target: sendToMail,
    };
    if (!body.name) {
      dispatch(createAlert('Please enter your name', AlertTypes.error));
      return;
    }
    if (!body.objet) {
      dispatch(createAlert('Please enter an object', AlertTypes.error));
      return;
    }
    if (!body.mail) {
      dispatch(createAlert('Please enter your email', AlertTypes.error));
      return;
    }
    if (!body.phone) {
      dispatch(createAlert('Please enter your phone number', AlertTypes.error));
      return;
    }
    if (!body.message) {
      dispatch(createAlert('Please enter your message', AlertTypes.error));
      return;
    }
    if (!body.target) {
      dispatch(createAlert('Please select an option', AlertTypes.error));
      return;
    }
    try {
      await axios.post(`${baseApiUrl}/basic/contact`, body);
      dispatch(createAlert('Message successfuly sent.', AlertTypes.success));
    } catch (error: any) {
      console.log(error.message);
      dispatch(createAlert('Error. Message not sent.', AlertTypes.error));

      console.log(error);
    }
  };

  const renderFormDesktop = () => (
    <div className={`${styles.selectorContainer} ${selectedTab === TabTypes.ECRIRE ? 'd-block' : 'd-none'}`}>
      <div className={styles.selectorSendTo}>
        <div
          aria-hidden
          onClick={() => {
            setShowSelectorOptions(!showSelectorOptions);
          }}
          className={styles.selectorTitle}
        >
          {sendToMail.length < 1 ? <p>Je souhaite...</p> : <p>{sendToName}</p>}
          <img src={arrowCorner} alt="corner" />
        </div>
        {showSelectorOptions && (
          <div
            className={[
              styles.selectorOptions,
              styles.fadeInSelector,
            ].join(' ')}
          >
            {selectorItems.map((item) => (
              <input
                key={item.name}
                onClick={() => {
                  setSendToMail(item.value);
                  setSendToName(item.name);
                  setShowSelectorOptions(false);
                }}
                type="button"
                value={item.name}
                name={item.value}
              />
            ))}
          </div>
        )}
      </div>
      <form className={styles.contactPageForm}>
        {deviceType === 'desktop'
          && <div className={styles.contactPageFormInputs}>
            <input
              placeholder="Nom"
              name="name"
              onChange={(e) => handleFormChange(e)}
            />
            <input
              placeholder="Objet"
              name="objet"
              onChange={(e) => handleFormChange(e)}
            />
            <input
              placeholder="Mail"
              name="mail"
              onChange={(e) => handleFormChange(e)}
            />
            <input
              placeholder="Téléphone"
              name="phone"
              onChange={(e) => handleFormChange(e)}
            />
          </div>
        }
        {deviceType === 'mobile'
          && <div className={styles.contactPageFormInputsMobile}>
            <div className={styles.mobileInputsTopRow}>
              <input
                placeholder="Nom"
                name="name"
                onChange={(e) => handleFormChange(e)}
              />
              <input
                placeholder="Mail"
                name="mail"
                onChange={(e) => handleFormChange(e)}
              />
              <input
                placeholder="Téléphone"
                name="phone"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <input
              placeholder="Objet"
              name="objet"
              onChange={(e) => handleFormChange(e)}
              style={{width: '75%'}}
            />
          </div>
        }

        <div className={styles.formTextArea}>
          <TextareaAutosize
            className={styles.textAreaMessage}
            placeholder="Message"
            name="message"
            onChange={(e) => {
              handleFormChange(e);
            }}
            rows={1}
          />
          <div className={styles.arrowLogoStyle}>
            <button
              className={styles.contactPageFormSubmit}
              type="submit"
              onClick={(e) => handleFormSubmit(e)}
              onKeyPress={(e) => {
                console.log(e.key);
              }}
            >
              <img src={arrowLogo} alt="arrow-logo" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  const renderMeeting = () => (
    <div className={`${styles.meetingContainer} ${selectedTab === TabTypes.RDV ? 'd-block' : 'd-none'} }`}>
      <InlineWidget
      url="https://calendly.com/zacharie-bouhaya-1"
      styles={{
        height: '100vh',
      }}
      />

    </div>
  );

  return (
    <div className={styles.backgroundContainer}>
        <Helmet>
          <title>ATP Contact</title>
          <meta
            name="description"
            content="Une nouvelle idée digitale à mettre en place ? Une émission pour un live Twitch ? Des besoins en production audiovisuelle ? Contactez-nous !"
          />
        </Helmet>
        <h3 className={[deviceType === 'desktop' ? styles.contactPageSideTitle : styles.contactPageMobileTitle].join(' ')}>
          Nous <span style={{fontFamily: 'HussarBold'}}>contacter</span>
        </h3>
        {showSelectorOptions && (
          <div
            className={styles.closeSelector}
            aria-hidden
            onClick={() => setShowSelectorOptions(false)}
          />
        )}
        <div className={styles.contactPageContainer}>

          <div className={styles.contactPageFormDescription}>
            <p>
              Alt Tab vous accompagne, du <span>conseil</span> à la{' '}
              <span>co-création d&apos;activations esport</span>, de{' '}
              <span>l&apos;idée</span> à la <span>prestation technique</span>,
              pour que votre marque bénéficie d&apos;un investissement bénéfique
              dans l&apos;esport et le jeu vidéo.
            </p>
            <p>
              Contactez-nous pour que l&apos;on vous propose une solution clé en
              main
            </p>
          </div>
          <div className={styles.mediaPageNav}>
            <a
              // className={selectedTab === 1 ? styles.mediaActiveTab : ''}
              style={{textAlign: 'right', justifyContent: 'flex-end'}}
              className={[selectedTab === TabTypes.ECRIRE && styles.mediaActiveTab].join(' ')}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(TabTypes.ECRIRE);
              }}
              href="/"
            >
              <p>NOUS ÉCRIRE</p>
            </a>
            <span style={{fontSize: '2rem', margin: 'auto 3rem auto 3rem'}}>
              •
            </span>
            <a
              style={{textAlign: 'left', justifyContent: 'flex-start'}}
              className={selectedTab === TabTypes.RDV ? styles.mediaActiveTab : ''}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(TabTypes.RDV);
              }}
              href="/"
            >
              <p>PRENDRE RDV</p>
            </a>
          </div>
          {renderMeeting()}
          {renderFormDesktop()}

        </div>
      <Footer />
    </div>

  );
};

export default ContactUs;
