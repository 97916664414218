import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {RootState} from 'src/app/rootReducer';
import Image from 'src/components/Image';
import footerLogo from '../../assets/logo/logo_horizontal_diagonal_blanc.png';
import styles from './Footer.module.css';

const Footer = () => {
  const {deviceType} = useSelector((s:RootState) => s.main);
  return (
    <div className={`${styles.footerContainer} d-flex justify-content-between`}>
      {deviceType === 'desktop'
      && <div>
        <div className={styles.link}>
          <Link to="/vision">
            <span>N</span>OTRE VISION
          </Link>
        </div>
        <div className={styles.link}>
          <Link to="/realisations">
            <span>R</span>EALISATIONS
          </Link>
        </div>
        <div className={styles.link}>
          <Link to="/members">
            <span>T</span>EAM
          </Link>
        </div>
        <div className={styles.link}>
          <Link to="/media">
            <span>M</span>EDIA
          </Link>
        </div>
        <div className={styles.link}>
          <Link to="/contact">
            <span>C</span>ONTACT
          </Link>
        </div>
      </div>
      }
      <div>
        <Image
          src={footerLogo}
          placeholderImg={footerLogo}
          className={styles.logo}
        />
      </div>
      {deviceType === 'desktop'
      && <div>
        <div className={styles.link}>
            <Link to="/mentions-legales">
            <span>M</span>ENTIONS LEGALES
          </Link>
        </div>
        <div className={styles.link}>
          <Link to="/CGU">
            <span>CGU & D</span>ONNEES
          </Link>
        </div>
        <div>&copy; ALTTAB PRODUCTIONS - 2022</div>
      </div>
      }
    </div>

  );
};

export default Footer;
