/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AppThunk} from './helpers';
import {Partner} from './partners';
import {Talent} from './talents';

export interface Event {
  id: string;
  clients: Partner[];
  title: string;
  game: string;
  link: string;
  description: string;
  start_date: Date;
  end_date: Date;
  videos: string[];
  photos: string[];
  diffusion: string;
  vod: string[];
  talents: Talent[];
  tags: string[];
  thumbnail: string;
  banner: string;
  ressources: Ressource[];
  displayRank: number;
}

export interface Ressource {
  _id: string;
  description: string;
}
export interface EventSimple {
  id: string;
  clients: string[];
  title: string;
  game: string;
  link: string;
  description: string;
  start_date: Date;
  end_date: Date;
  videos: string[];
  photos: string[];
  diffusion: string;
  vod: string[];
  talents: string[];
  tags: string[];
  thumbnail: string;
  ressources: string[];
  displayRank: number;
}

export interface EventState {
  eventsList: Event[];
  eventsListSimple: EventSimple[];
  eventDetails: Event | null;
  detailsLoaded: boolean;
}
const initialState: EventState = {
  eventsList: [],
  eventsListSimple: [],
  eventDetails: null,
  detailsLoaded: false,
};

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    eventsLoaded(state, {payload}: PayloadAction<Event[]>) {
      state.eventsList = payload;
    },
    eventDetailsLoaded(state, {payload}: PayloadAction<Event>) {
      state.eventDetails = payload;
      state.detailsLoaded = true;
    },
    simpleEventsLoaded(state, {payload}: PayloadAction<EventSimple[]>) {
      state.eventsListSimple = payload;
    },
  },
});

export default events.reducer;

export const {eventsLoaded, eventDetailsLoaded, simpleEventsLoaded} = events.actions;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadEventsList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/events/fetch/all`);
    dispatch(eventsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadEventById = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/events/fetch/${id}`);
    dispatch(eventDetailsLoaded(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const loadEventByTitle = (title: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/events/fetch/title/${title}`);
    dispatch(eventDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadEventsListSimple = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/events/fetch/all/simple`);
    dispatch(simpleEventsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};
