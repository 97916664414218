/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AppThunk} from './helpers';

export interface Member {
  _id: string;
  firstname: string;
  lastname: string;
  member_since: Date;
  position: string;
  age: number;
  description: string;
  pole: string;
  imageUrl: string;
}

export interface MemberState {
  membersList: Member[];
}

const initialState: MemberState = {
  membersList: [],
};

const members = createSlice({
  name: 'members',
  initialState,
  reducers: {
    membersLoaded(state, {payload}: PayloadAction<Member[]>) {
      state.membersList = payload;
    },
  },
});

export const {membersLoaded} = members.actions;

export default members.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadMembersList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/members/fetch/all`);

    dispatch(membersLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};
