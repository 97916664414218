/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useState, useEffect} from 'react';
import {useAppSelector} from 'src/hooks/hooks';
import Compteur from '../Compteur';
import styles from './CompteurContainer.module.css';

const CompteurContainer = () => {
  // DOM
  const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>();
  const [compteurContainer, setCompteurContainer] = useState<HTMLElement | null>();
  const {deviceType} = useAppSelector((s) => s.main);

  useEffect(() => {
    const getCompteurPos = (e:any, container:any, compteur:any) => {
      if (e && container && compteur) console.log('');
      if (scrollContainer && compteurContainer) {
        const containerTop = scrollContainer.getBoundingClientRect().top;
        const containerBot = scrollContainer.getBoundingClientRect().bottom;
        const v = (containerBot - window.innerHeight) * 1.5;
        compteurContainer.style.transform = `translateY(${v < 0 ? v : 60}px)`;

        if (containerTop > 0) {
          compteurContainer.style.position = 'absolute';
          compteurContainer.style.top = '60px';
          compteurContainer.style.bottom = '';
        } else {
          compteurContainer.style.position = 'fixed';
          compteurContainer.style.top = '60px';
          compteurContainer.style.bottom = '';
        }
      }
    };
    setScrollContainer(document.querySelector(`.${styles.scrollContainer}`) as HTMLElement);
    setCompteurContainer(document.querySelector(`.${styles.compteurContainerDesktop}`) as HTMLElement);
    getCompteurPos(null, scrollContainer, compteurContainer);
    window.addEventListener('scroll', (e) => {
      getCompteurPos(e, scrollContainer, compteurContainer);
    });

    return () => {
      setScrollContainer(null);
      setCompteurContainer(null);
    };
  }, [compteurContainer, scrollContainer]);

  return (
    <div
      className={[styles.scrollContainer].join(' ')}
      id="parallaxContainerID"
    >

      {/* # # # # # # # # # # # #  */}
      {/* DESKTOP COMPTEUR CONTAINER */}
      {/* # # # # # # # # # # # #  */}
      {deviceType === 'desktop'
      && <div
        className={styles.compteurContainerDesktop}
        // data-aos="fade-up"
        >
          <div id="sloganTop" className={styles.sloganTop}>
            IMAGINE, PRODUCE, CONNECT WITH ALT TAB
          </div>
          <div className={`${[styles.startCounter, styles.compteurTile].join(' ')}`}>
            <Compteur amount={10} description="ANS D'EXPÉRIENCE DANS L'EVENT ET LE DIGITAL" />
          </div>
          <div className={styles.compteurTile}>
            <Compteur
              amount={17}
              description="DE CHIFFRE D'AFFAIRES"
              suffix="M€"
            />
          </div>
          <div className={styles.compteurTile}>
            <Compteur
              amount={1300}
              description="DE LOCAUX"
              suffix="m²"
            />
          </div>
          <div className={styles.compteurTile}>
            <Compteur
              amount={2000}
              description="HEURES DE CONTENU DIFFUSÉES"
              suffix="+"
            />
          </div>
        </div>
      }
      {/* # # # # # # # # # # # #  */}
      {/* MOBILE COMPTEUR CONTAINER */}
      {/* # # # # # # # # # # # #  */}
      {deviceType === 'mobile'
      && <div
      className={styles.compteurContainerMobile}
      >
        <div className={[styles.firstRowMobileCompteur, styles.mobileTileRow].join(' ')}>
          <div className={[styles.startCounter, styles.compteurMobileTile].join(' ')}>
            <Compteur amount={10} description="ANS D'EXPÉRIENCEE DANS L'EVENT ET LE DIGITAL" />
          </div>
          <div className={styles.compteurMobileTile}>
            <Compteur
              amount={2}
              description="SPECTATEUR-ICES UNIQUES PAR MOIS"
              suffix="M"
              decimals={2}
            />
          </div>

        </div>
        <div className={[styles.firstRowMobileCompteur, styles.mobileTileRow].join(' ')}>
            <div className={styles.compteurMobileTile}>
              <Compteur amount={40} description="INCROYABLES TALENTS" />
            </div>
            <div className={styles.compteurMobileTile}>
              <Compteur
                amount={17}
                description="DE CHIFFRES D'AFFAIRES"
                suffix="M€"
              />
            </div>
        </div>
        <div className={[styles.secondRowMobileCompteur, styles.mobileTileRow].join(' ')}>

          <div className={styles.compteurMobileTile}>
            <Compteur
              amount={1300}
              description="DE LOCAUX"
              suffix="m²"
            />
          </div>
          <div className={styles.compteurMobileTile}>
            <Compteur
              amount={2000}
              description="HEURE DE CONTENU DIFFUSÉ"
              suffix="+"
            />
          </div>
        </div>
      </div>}
      {/* </Parallax> */}
    </div>
  );
};

export default CompteurContainer;
