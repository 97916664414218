/* eslint-disable no-underscore-dangle */
import {useCallback, useEffect, useState} from 'react';
import TalentCard from 'src/components/TalentCard';
import {loadTalentsList, Talent} from 'src/features/talents';
import {Parallax} from 'react-parallax';
import Aos from 'aos';
import Footer from 'src/components/Footer';
import {HiOutlineBookOpen} from 'react-icons/hi';
import styles from './AgencePage.module.css';
import carrerManagementImg from '../../assets/background/carrer-management.jpg';
import contentCreationImg from '../../assets/background/content-creation.jpg';
import imageDevelopmentImg from '../../assets/background/image-development.jpg';
import mosaique from '../../assets/photos/mosaique_pc.png';
import mosaiqueMobile from '../../assets/photos/mosaique_mobile.png';
import altTabNetworkImg from '../../assets/background/alt-tab-network.jpg';
import performanceCenterImg from '../../assets/background/performance-center.jpg';
import topBanner from '../../assets/photos/world-agence-top-banner.jpg';
import alderiateRhobalas from '../../assets/photos/agence/alderiate-rhobalas.jpg';
import jeanMassiet from '../../assets/photos/agence/jean-massiet.jpg';
import maghla from '../../assets/photos/agence/maghla.jpg';
import {Helmet} from 'react-helmet';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

enum ServicesList {
  NONE = '',
  PROMOTION = 'PROMOTION',
  SPONSORING = 'SPONSORING',
  BOOKING = 'BOOKING',
  CONTENTCREATION = 'CONTENTCREATION',
  CATALOGUE = 'CATALOGUE',
}

const talentsList = [
  {
    _id: '2',
    photo: alderiateRhobalas,
    title: 'Talent',
    firstname: 'Alderiate - Rhobalas',
    lastname: '',
    nickname: '',
    description: 'MSI Netflix',
    nationality: 'FR',
    game: 'LoL',
    teamName: 'None',
    birth_date: new Date(),
    member_since: new Date(),
    events: null,
    displayRank: 1,
  },
  {
    _id: '3',
    photo: jeanMassiet,
    title: 'Talent',
    firstname: 'Jean Massiet',
    lastname: '',
    nickname: '',
    description: 'Backseat',
    nationality: 'FR',
    game: 'Politics',
    teamName: 'None',
    birth_date: new Date(),
    member_since: new Date(),
    events: null,
    displayRank: 2,
  },
  {
    _id: '4',
    photo: maghla,
    title: 'Talent',
    firstname: 'Maghla',
    lastname: '',
    nickname: '',
    description: 'E3Gnarok',
    nationality: 'FR',
    game: 'Divers',
    teamName: 'None',
    birth_date: new Date(),
    member_since: new Date(),
    events: null,
    displayRank: 3,
  },
];
const AgencePage = () => {
  const dispatch = useAppDispatch();
  const {deviceType} = useAppSelector((s) => s.main);
  const [currentService, setCurrentService] = useState<ServicesList>();

  const [sortedTalentsList, setSortedTalentsList] = useState<Talent[]>();

  Aos.init({duration: 1000});

  useEffect(() => {
    dispatch(loadTalentsList());
  }, [dispatch]);
  const handleServiceEnter = useCallback((service: ServicesList) => {
    setCurrentService(service);
  }, []);

  // Sort talentsList by displayRank
  useEffect(() => {
    if (talentsList) {
      const newtalentsList = [...talentsList];
      const sorted = newtalentsList.sort((a, b) => a.displayRank - b.displayRank);
      setSortedTalentsList(sorted);
    }
  }, []);

  // All animations
  useEffect(() => {
    if (deviceType === 'desktop') {
      // const whoAreWe = document.querySelector(`.${styles.whoAreWeTitle}`) as HTMLElement;
      // const ourServices = document.querySelector(`.${styles.ourServicesTitle}`) as HTMLElement;
      const theyTrustUs = document.querySelector(`.${styles.theyTrustUsTitles}`) as HTMLElement;
      const successfulStory = document.querySelector(`.${styles.successfulStoryTitle}`) as HTMLElement;
      const bannerTopText = document.querySelector(`.${styles.bannerTopText}`) as HTMLElement;
      const bottomBanner = document.querySelector(`.${styles.bottomBanner}`) as HTMLElement;
      const bannerBottomText = document.querySelector(`.${styles.bannerBottomText}`) as HTMLElement;
      const pageTitle = document.querySelector(`.${styles.pageTitle}`) as HTMLElement;
      const servicesContainerParent = document.querySelector(`.${styles.servicesContainerParent}`) as HTMLElement;
      window.addEventListener('scroll', () => {
        // Side title remove position fixed
        if (pageTitle && servicesContainerParent) {
          const v = servicesContainerParent.getBoundingClientRect().top - window.innerHeight;
          // State n°0
          if (v > 0) { pageTitle.style.top = ''; }
          if (v < 0) { pageTitle.style.top = `${v / 10}%`; }
        }
        if (theyTrustUs) {
          const theyTrustUsPos = theyTrustUs.getBoundingClientRect().top;
          theyTrustUs.style.transform = `translateX( -${
            theyTrustUsPos < 0 ? 0 : theyTrustUsPos / 8
          }rem)`;
        }
        // SUCCESSFUL STORY horizontal animation
        if (successfulStory) {
          // const successfulStoryPos = successfulStory.getBoundingClientRect().top;
          // successfulStory.style.transform = `translateX(-${
          //   successfulStoryPos < 0 ? 0 : successfulStoryPos / 8
          // }rem)`;
        }
        // bottom BANNER TOP TEXT horizontal animation
        if (bannerTopText) {
          // const bannerTopTextPos = bannerTopText.getBoundingClientRect().top - window.innerHeight / 2;
        }
        // bottom BANNER horizontal animation
        if (bottomBanner) {
          const bottomBannerPos
            = bottomBanner.getBoundingClientRect().top - window.innerHeight / 2;
          bottomBanner.style.transform = `translateX(-${
            bottomBannerPos < 0 ? 0 : bottomBannerPos / 8
          }rem)`;
        }
        // bottom BANNER bottom text horizontal animation
        if (bannerBottomText) {
          const bannerBottomTextPos
            = bannerBottomText.getBoundingClientRect().top
            - window.innerHeight / 1.2;
          bannerBottomText.style.transform = `translateX(-${
            bannerBottomTextPos < 0 ? 0 : bannerBottomTextPos / 4
          }rem)`;
        }
      });
    }
  }, [deviceType]);

  const handleServiceLeave = useCallback(() => {
    setCurrentService(ServicesList.NONE);
  }, []);

  const renderDownloadCatalogueDesktop = () => (
      <p className={styles.downloadButtonP}>
        <a href="http" className={styles.buttonElement}>
            <span className={styles.borderDLButton} />
            <span className={styles.topDLButton} />
            <span className={styles.rightDLButton} />
            <span className={styles.bottomDLButton} />
            <span className={styles.leftDLButton} />
            <a href="/files/catalogue_talents.pdf" className={styles.textDLButton} target="_blank">Télécharger le catalogue</a>
        </a>
      </p>
  );

  const servicesContainerDesktop = () => (
      <div className={`${styles.servicesContainerDesktop}`}>
        <div
          className={`${styles.service}`}
          onMouseLeave={() => handleServiceLeave()}
          onMouseEnter={() => handleServiceEnter(ServicesList.PROMOTION)}
          data-aos={deviceType === 'desktop' ? 'fade-up' : ''}
          data-aos-delay="20"
        >
          <img
            src={carrerManagementImg}
            className={styles.serviceImage}
            alt="service"
          />
          {currentService === ServicesList.PROMOTION ? (
            <div className={styles.serviceDescription}>
              Dans le cadre de lancements de jeux, de produits ou de service nous aidons les
              marques à identifier et activer les leaders de communautés correspondants à leurs cibles.
            </div>
          ) : (
            <div className={styles.serviceText}>
              <p className={styles.serviceTextSpanContainer}>PROMOTION&nbsp;{' '}
              </p>
            </div>
          )}
        </div>
        <div
          className={`${styles.service} `}
          onMouseLeave={() => handleServiceLeave()}
          onMouseEnter={() => handleServiceEnter(ServicesList.SPONSORING)}
          data-aos={deviceType === 'desktop' ? 'fade-down' : ''}
          data-aos-delay="70"
        >
          <img
            src={contentCreationImg}
            className={styles.serviceImage}
            alt="service"
          />
          {currentService === ServicesList.SPONSORING ? (
            <div className={styles.serviceDescription}>
              Nous permettons à nos clients de s&apos;associer durablement à un créateur de contenu, à un
              jeu ou à un média, afin de construire une légitimité et une histoire auprès de leurs communautés.
            </div>
          ) : (
            <div className={styles.serviceText}>
              <p className={styles.serviceTextSpanContainer}>SPONSORING&nbsp;{' '}</p>
            </div>
          )}
        </div>
        <div
          className={`${styles.service} `}
          onMouseLeave={() => handleServiceLeave()}
          onMouseEnter={() => handleServiceEnter(ServicesList.CATALOGUE)}
          data-aos={deviceType === 'desktop' ? 'fade-up' : ''}
          data-aos-delay="280"
        >
          <img
            src={performanceCenterImg}
            className={styles.serviceImage}
            alt="service"
          />
          {currentService === ServicesList.CATALOGUE ? (
            <div className={styles.serviceDescription}>
              <p>Découvrez les talents les plus connus avec lesquels nous collaborons régulièrement et les nouvelles pépites que nous faisons émerger.</p>
              <div>
                {renderDownloadCatalogueDesktop()}
              </div>
            </div>
          ) : (
            <div className={styles.serviceText}>
              <p className={styles.serviceTextSpanContainer}>CATALOGUE</p>
            </div>
          )}
        </div>
        <div
          className={`${styles.service} `}
          onMouseLeave={() => handleServiceLeave()}
          onMouseEnter={() => handleServiceEnter(ServicesList.BOOKING)}
          data-aos={deviceType === 'desktop' ? 'fade-up' : ''}
          data-aos-delay="140"
        >
          <img
            src={imageDevelopmentImg}
            className={styles.serviceImage}
            alt="service"
          />
          {currentService === ServicesList.BOOKING ? (
            <div className={styles.serviceDescription}>
              Animation de stand ou d&apos;émission, commentaire de
              compétition, publicité, le catalogue et le réseau de
              talent d&apos;Alt Tab permet à ses clients d&apos;incarner leurs prises de paroles.
            </div>
          ) : (
            <div className={styles.serviceText}>
              <p className={styles.serviceTextSpanContainer}>
                BOOKING&nbsp;{' '}
              </p>
            </div>
          )}
        </div>
        <div
          className={`${styles.service} `}
          onMouseLeave={() => handleServiceLeave()}
          onMouseEnter={() => handleServiceEnter(ServicesList.CONTENTCREATION)}
          data-aos={deviceType === 'desktop' ? 'fade-down' : ''}
          data-aos-delay="210"
        >
          <img
            src={altTabNetworkImg}
            className={styles.serviceImage}
            alt="service"
          />
          {currentService === ServicesList.CONTENTCREATION ? (
            <div className={styles.serviceDescription}>
              Nous accompagnons les talents dans la conception,
              le financement et la réalisation de contenus forts
              dédiés à leur communautés et permettons aux marques
              de s&apos;intégrer de manière cohérente dans leurs univers.
            </div>
          ) : (
            <div className={styles.serviceText}>
                <p className={styles.serviceTextSpanContainer}>CREATION DE&nbsp;{' '}
                  <span className={styles.serviceTextSpan}>CONTENUS</span></p>
            </div>
          )}
        </div>

      </div>
  );

  const servicesContainerMobile = () => (
      <div className={`${styles.servicesContainerMobile}`}>

{/* # # # # # # */}
{/* # #  1  # # */}
{/* # # # # # # */}
{/* currentService === ServicesList.SPONSORING */}
        <div className={`${styles.serviceMobile}`}>
          <img src={carrerManagementImg} className={styles.serviceImageMobile} alt="service" />
          <div className={styles.serviceTextMobile}>
            <p className={styles.serviceTextSpanContainerMobile}>PROMOTION</p>
            <p className={styles.serviceDescriptionMobile}>
              Dans le cadre de lancement de jeux, de produits ou de service nous aidons les
              marques à identifier et activer les leaders de communautés correspondant à leurs cibles.
            </p>
          </div>
        </div>

{/* # # # # # # */}
{/* # #  2  # # */}
{/* # # # # # # */}
{/* currentService === ServicesList.BOOKING */}
        <div className={`${styles.serviceMobile}`}>
          <img src={imageDevelopmentImg} className={styles.serviceImageMobile} alt="service" />
          <div className={styles.serviceTextMobile}>
            <p className={styles.serviceTextSpanContainerMobile}>SPONSORING</p>
            <p className={styles.serviceDescriptionMobile}>
              Animation de stand ou d&apos;émission, commentaire de
              compétition, publicité, le catalogue et le réseau de
              talent d&apos;Alt Tab permet à ses clients d&apos;incarner leurs prises de paroles.
            </p>
          </div>
        </div>

{/* # # # # # # */}
{/* # #  3  # # */}
{/* # # # # # # */}
{/* currentService === ServicesList.CONTENTCREATION */}
        <div className={`${styles.serviceMobile}`}>
          <img src={altTabNetworkImg} className={styles.serviceImageMobile} alt="service" />
          <div className={styles.serviceTextMobile}>
            <p className={styles.serviceTextSpanContainerMobile}>CATALOGUE</p>
            <p className={styles.serviceDescriptionMobile}>
              Découvrez les talents les plus connus avec lesquels nous collaborons
              régulièrement et les nouvelles pépites que nous faisons émerger.
            </p>
            <div className={styles.serviceTalentDownloadButtonContainer}>
              <a href="/files/catalogue_talents.pdf" className={styles.serviceTalentDownloadButton}>
                <p className={styles.catalogueLinkMobile}>Voir notre eBook de talents</p>
                <HiOutlineBookOpen />
              </a>
            </div>
          </div>
        </div>

{/* # # # # # # */}
{/* # #  4  # # */}
{/* # # # # # # */}
{/* currentService === ServicesList.CATALOGUE */}
        <div className={`${styles.serviceMobile}`}>
          <img src={contentCreationImg} className={styles.serviceImageMobile} alt="service" />
          <div className={styles.serviceTextMobile}>
            <p className={styles.serviceTextSpanContainerMobile}>CREATION DE CONTENUS</p>
            <p className={styles.serviceDescriptionMobile}>
              Nous accompagnons les talents dans la conception,
              le financement et la réalisation de contenus forts
              dédiés à leur communautés et permettons aux marques
              de s&apos;intégrer de manière cohérente dans leurs univers.
            </p>
          </div>
        </div>

{/* # # # # # # */}
{/* # #  5  # # */}
{/* # # # # # # */}

        <div className={`${styles.serviceMobile}`}>
          <img src={performanceCenterImg} className={styles.serviceImageMobile} alt="service" />
          <div className={styles.serviceTextMobile}>
            <p className={styles.serviceTextSpanContainerMobile}>CATALOGUE</p>
            <p className={styles.serviceDescriptionMobile}>
              Découvrez les talents les plus connus avec lesquels nous collaborons
              régulièrement et les nouvelles pépites que nous faisons émerger.
            </p>
          </div>
        </div>
      </div>
  );

  return (
    <div className={styles.pageContainer}>
      <Helmet>
        <title>ATP Agence</title>
        <meta
          name="description"
          content="Contactez les plus grands talents d'internet directement au sein de notre agence ou depuis notre catalogue d'influencers proches."
        />
      </Helmet>
      <div className={styles.pageTitle}>
        <span className={styles.titleSpan}>AGENCE DE </span> TALENTS
      </div>
      <div className={styles.background1}>
        <div className={styles.topBannerContainer}>
          <Parallax
            bgImage={topBanner}
            strength={200}
            bgImageStyle={{height: '100%', objectFit: 'cover'}}
          >
            <div style={{height: deviceType === 'desktop' ? '70vh' : '40vh'}} />
          </Parallax>
        </div>
        <div data-aos={deviceType === 'desktop' ? 'fade-right' : ''} className={[styles.bigText, styles.whoAreWeTitle].join(' ')}>
          <p>QUI SOMMES-NOUS ?</p>
        </div>
        <div data-aos={deviceType === 'desktop' ? 'fade-up' : ''} className={styles.introduction}>
          <p>
            ALT TAB JOUE UN
            <span className={styles.introductionSpan}> RÔLE CENTRAL </span>
            <span className={styles.introductionSpan}>DANS LA CONSTRUCTION DES COMMUNAUTÉS </span>
            PARMIS LES PLUS ACTIVES ET LES PLUS IMPORTANTES DU WEB FRANCOPHONE.
          </p>
          <div className="mt-4">
            <p>
              PRÉCURSEUR
              <span className={styles.introductionSpan}> EN GAMING ET ESPORT </span>
              FRANCOPHONE DEPUIS 2011, NOUS ACCOMPAGNONS LES CHANGEMENTS DE CONSOMMATION MÉDIATIQUES APPORTÉES PAR LA
              <span className={styles.introductionSpan}> DÉMOCRATISATION DE LA VIDÉO ET DU LIVE SUR INTERNET. </span>
              CE FAISANT, NOUS PARTICIPONS À DÉFINIR LES
              <span className={styles.introductionSpan}> FORMATS DE RÉFÉRENCES </span>
              ET À FAIRE ÉMERGER LES VISAGES DU WEB DE DEMAIN.
            </p>
          </div>
          <div className="mt-4">
            <p>
              AUJOURD&apos;HUI NOUS PERMETTONS À NOS PARTENAIRES DE
              <span className={styles.introductionSpan}> NOUER DES LIENS FORTS AVEC LEURS PUBLICS CIBLES </span>
              À TRAVERS NOTRE RÉSEAU DE PERSONNALITÉS.
            </p>
          </div>
          <div className="mt-4">
            <p>
              ALT TAB
              <span className={styles.introductionSpan}> ACCOMPAGNE LES TALENTS D&apos;AUJOURD&apos;HUI ET DE DEMAIN </span>
              DANS LA RÉALISATION DE CONTENUS FORTS ET IMPACTANTS, QUI CORRESPONDE À LEUR VISION, LEURS ENVIES ET LEURS COMMUNAUTÉS.
            </p>
          </div>

        </div>
        <div data-aos={deviceType === 'desktop' ? 'fade-down' : ''} className={[styles.bigText, styles.ourServicesTitle].join(' ')}>
          NOS SERVICES
        </div>
        <div className={styles.servicesContainerParent}>

          {/* # # # # # # # # # # # # */}
          {/* SERVICES DESKTOP */}
          {/* # # # # # # # # # # # # */}

          {deviceType === 'desktop' && servicesContainerDesktop()}
          {deviceType === 'mobile' && servicesContainerMobile()}
        </div>
      </div>

      <div className={`${styles.background2}`}>
        <div data-aos={deviceType === 'desktop' ? 'fade-up' : ''} className={[styles.bigText, styles.theyTrustUsTitles].join(' ')}>
          ILS NOUS FONT CONFIANCE
        </div>
        <div className={styles.talentsContainerParent}>
          <div className={styles.talentsContainer}>
            {sortedTalentsList
            && sortedTalentsList.map(
              (talent: Talent, index: number) => index < 3 && (
                  <TalentCard
                    key={talent._id}
                    talent={talent}
                    width={500}
                    height={400}
                    index={index}
                    pageAgence
                  />
              ),
            )}
          </div>
        </div>

        {/* <div className={styles.background3}>
          <div className={styles.showreelContainer}>
            <div className={styles.plateauBleu} />
            <div className={styles.plateauBleuHeaders}>
              <div data-aos="fade-left" className={styles.pbhTop}>
                <p>
                  UNE ÉQUIPE
                </p>
              </div>
              <div data-aos="fade-left" className={styles.pbhLeft}>
                <p>
                  Aux compétences <span>rares et transverses</span>
                </p>
              </div>
              <div data-aos="fade-up" className={styles.pbhMid}>
                <p>
                  Prête à{' '}
                  <span>rebondir et à affronter de nouveaux défis</span>
                </p>
              </div>
              <div data-aos="fade-right" className={styles.pbhRight}>
                <p>
                  Proposant de{' '}
                  <span>nouvelles idées à la pointe de la technologie</span>
                </p>
              </div>
            </div>
            <div className={styles.footerContainer}>
            </div>
          </div>
        </div> */}
        {deviceType === 'desktop' ? (
          <img src={mosaique} alt="mosaique" className={styles.mosaique} />
        ) : (
          <img src={mosaiqueMobile} alt="mosaique" className={styles.mosaiqueMobile} />
        ) }
        {deviceType === 'desktop' && <Footer />}
      </div>
    </div>
  );
};

export default AgencePage;
