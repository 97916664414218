/* eslint-disable react/require-default-props */
import {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import {useAppSelector} from 'src/hooks/hooks';
import styles from './Compteur.module.css';

interface CompteurInterface {
  amount: number;
  description: string;
  suffix?: string;
  decimals?: number;
}

const Compteur = ({
  amount,
  description,
  suffix,
  decimals,
}: CompteurInterface) => {
  const [counterIsStarted, setCounterIsStarted] = useState<boolean | null>(false);
  const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>();
  const [heroVideoContainer, setHeroVideoContainer] = useState<HTMLElement | null>();
  const {deviceType} = useAppSelector((s) => s.main);

  useEffect(() => {
    function triggerCompteur(e:any, first:any, second:HTMLElement) {
      const scrollPos = window.scrollY;
      const heroVideoBottom = first.offsetHeight;
      const scrollContainerBottom = first.offsetHeight + second.offsetHeight;

      if ((scrollPos) < (heroVideoBottom * 0.2)) {
        if (counterIsStarted !== false) {
          setCounterIsStarted(false);
        }
      } else if (scrollPos > (heroVideoBottom * 0.2)
      && scrollPos < scrollContainerBottom - (window.innerHeight * 0.5)) {
        if (counterIsStarted !== true) {
          setCounterIsStarted(true);
        }
      } else if (scrollPos > scrollContainerBottom - (window.innerHeight * 0.5)) {
        if (counterIsStarted !== false) {
          setCounterIsStarted(false);
        }
      }
    }
    function triggerMobileCompteur(scrollContainerMobile:HTMLElement) {
      const {top, bottom} = scrollContainerMobile.getBoundingClientRect();
      if (bottom + window.innerHeight > 0) {
        if (bottom <= 0 || (top - window.innerHeight >= 0 && bottom <= 0) || window.scrollY === 0) {
          if (counterIsStarted !== false) {
            setCounterIsStarted(false);
          }
        } else if (bottom >= 0 || (top <= 0 && bottom >= 0)) {
          if (counterIsStarted !== true) {
            setCounterIsStarted(true);
          }
        }
      } else {
        setCounterIsStarted(null);
      }
    }
    setScrollContainer(document.querySelector('#parallaxContainerID') as HTMLElement);
    setHeroVideoContainer(document.querySelector('#heroVideoContainerID') as HTMLElement);

    if (deviceType === 'desktop') {
      if (scrollContainer) {
        triggerCompteur(null, heroVideoContainer, scrollContainer);
        window.addEventListener('scroll', (e) => {
          triggerCompteur(e, heroVideoContainer, scrollContainer);
        });
      }
    } else if (deviceType === 'mobile') {
      window.addEventListener('scroll', () => {
        if (scrollContainer) {
          triggerMobileCompteur(scrollContainer);
        }
      });
    }
    return () => {
      setScrollContainer(null);
      setHeroVideoContainer(null);
    };
  }, [counterIsStarted, deviceType, heroVideoContainer, scrollContainer]);

  return (
    <div>
      <div className={styles.amount}>
        <CountUp
          duration={2}
          end={counterIsStarted ? amount : 0}
          suffix={suffix || ''}
          decimals={decimals || 0}
        />
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default Compteur;
