/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface MainState {
  videoPlayed: boolean;
  expertiseZoomPlayed: boolean;
  locations: any;
  deviceType: string | null;
}

const initialState: MainState = {
  // videoPlayed: false,
  videoPlayed: true,
  expertiseZoomPlayed: false,
  locations: null,
  deviceType: null,
};

const main = createSlice({
  name: 'main',
  initialState,
  reducers: {
    videoWasPlayed(state) {
      state.videoPlayed = true;
    },
    expertiseZoomWasPlayed(state) {
      state.expertiseZoomPlayed = true;
    },
    loadLocations(state, {payload}:PayloadAction<number[]>) {
      state.locations = payload;
    },
    unloadLocations(state) {
      state.locations = null;
    },
    deviceIsMobile(state) {
      state.deviceType = 'mobile';
    },
    deviceIsDesktop(state) {
      state.deviceType = 'desktop';
    },
  },
});
export const {videoWasPlayed, expertiseZoomWasPlayed, loadLocations, unloadLocations, deviceIsMobile, deviceIsDesktop} = main.actions;
export default main.reducer;

export const listAllEventListeners = () => {
  const allElements = Array.prototype.slice.call(document.querySelectorAll('*'));
  allElements.push(document);
  allElements.push(window);

  const types = [];

  for (const ev in window) {
    if (/^on/.test(ev)) types[types.length] = ev;
  }

  const elements = [];
  for (let i = 0; i < allElements.length; i++) {
    const currentElement = allElements[i];
    for (let j = 0; j < types.length; j++) {
      if (typeof currentElement[types[j]] === 'function') {
        elements.push({
          node: currentElement,
          type: types[j],
          func: currentElement[types[j]].toString(),
        });
      }
    }
  }

  return elements.sort((a, b) => a.type.localeCompare(b.type));
};
