import {Link} from 'react-router-dom';
import styles from './NotFoundPage.module.css';

const NotFoundPage = () => (
    <div className={styles.pageContainer}>
      <h1 className={styles.text}>404 - Not Found!</h1>
      <Link to="/" className={styles.link}>Let's get you back home</Link>
    </div>
);

export default NotFoundPage;
