/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AppThunk} from './helpers';

export interface Article {
  id: string;
  _id: string;
  url: string;
  image: string;
  title: string;
  displayRank: number;
}

export interface UpdateArticle {
  url: string;
  image: string;
  title: string;
}
export interface ArticleState {
  articlesList: Article[];
  articleDetails: Article | null;
}

const initialState: ArticleState = {
  articlesList: [],
  articleDetails: null,
};

const articles = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    articlesLoaded(state, {payload}: PayloadAction<Article[]>) {
      state.articlesList = payload;
    },
  },
});

export const {articlesLoaded} = articles.actions;

export default articles.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadArticlesList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/articles/fetch/all`);
    dispatch(articlesLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};
