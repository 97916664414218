/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import {useState, useEffect} from 'react';
import {setHomePagePartner} from 'src/features/partners';
import {useNavigate} from 'react-router-dom';
import styles from './Partenaires.module.css';

import partnersHaloImg from '../../assets/background/halo-1-short.png';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const Partenaires = () => {
  const {partnersListSimple} = useAppSelector((s) => s.partners);
  const {deviceType} = useAppSelector((s) => s.main);
  const newPartnersList = [...partnersListSimple];
  const sortedList = newPartnersList.sort((a, b) => a.displayRank - b.displayRank);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [partenairesContainer, setPartenairesContainer] = useState<HTMLElement | null>();
  const [gridContainer, setGridContainer] = useState<HTMLElement | null>();

  const redirectToPartnersEvents = (title: string) => {
    dispatch(setHomePagePartner(title));
    history('/realisations');
  };

  useEffect(() => {
    const getPartnersPos = (e:any, container:any, partnersGrid:any) => {
      if (partenairesContainer && gridContainer) {
        const containerTop = container.getBoundingClientRect().top;
        const containerBot = container.getBoundingClientRect().bottom;
        const v = (containerBot - window.innerHeight);
        gridContainer.style.transform = `translateY(${v < 0 ? v : 0}px)`;

        if (containerTop > 0) {
          gridContainer.style.position = 'absolute';
          gridContainer.style.top = '0';
          gridContainer.style.bottom = '';
        } else {
          gridContainer.style.position = 'fixed';
          gridContainer.style.top = '0';
          gridContainer.style.bottom = '';
        }
      }
    };
    setPartenairesContainer(document.querySelector(`.${styles.partenairesContainer}`) as HTMLElement);
    setGridContainer(document.querySelector(`.${styles.gridContainer}`) as HTMLElement);
    getPartnersPos(null, partenairesContainer, gridContainer);
    window.addEventListener('scroll', (e) => {
      getPartnersPos(e, partenairesContainer, gridContainer);
    });
    return () => {
      setPartenairesContainer(null);
      setGridContainer(null);
    };
  }, [gridContainer, partenairesContainer]);

  return (
    <div
      id="partenairesContainerID"
      className={`${styles.scrollContainer}`}
    >
      <div className={styles.partenairesBackground} />
      <div
        className={[styles.partenairesContainer].join(' ')}
        id="partenairesContainer"
      >
        {deviceType === 'desktop'
        && <div
        // data-aos="fade-up"
        className={styles.haloImgContainer}>
          <img
            src={partnersHaloImg}
            alt="partners-halo"
            className={styles.haloImg}
          />
        </div>
        }
        <div
        // data-aos="fade-up"
        className={[styles.gridContainer].join(' ')}
        >
          <div className={styles.title}>
            ILS NOUS FONT <span className={styles.span}>CONFIANCE</span>
          </div>
          <div className={[styles.cardBoard].join(' ')}>
            {sortedList.map((p, i:number) => i < 20 && (
                <div
                  key={p._id}
                  className={`${styles.cardContainer}`}
                  onClick={() => redirectToPartnersEvents(p.title)}
                >
                  <img
                    src={p.imageUrl}
                    alt=""
                    className={styles.partenaireImage}
                  />
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Partenaires;
