/* eslint-disable no-underscore-dangle */
import {useCallback, useEffect, useState} from 'react';
import EventCard from 'src/components/EventCard';
import {Event, loadEventsList} from 'src/features/events';
import {Select, CaretIcon, ModalCloseButton} from 'react-responsive-select';
import {loadPartnersListSimple, PartnerSimple, setHomePagePartner} from 'src/features/partners';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im';
import {createOptions} from 'src/utils/helper';
import Footer from 'src/components/Footer';
import {TagsTypes} from 'src/utils/tags';
import Aos from 'aos';
import realisationsBackground from '../../assets/background/background_realisation.jpg';
import mobileTabBg1 from '../../assets/background/realisations/1.png';
import mobileTabBg2 from '../../assets/background/realisations/2.png';
import mobileTabBg3 from '../../assets/background/realisations/3.png';
import mobileTabBg4 from '../../assets/background/realisations/4.png';
import mobileTabBg5 from '../../assets/background/realisations/5.png';
import mobileTabBg6 from '../../assets/background/realisations/6.png';
import mobileTabBg7 from '../../assets/background/realisations/7.png';
import mobileTabBg8 from '../../assets/background/realisations/8.png';
import 'react-responsive-select/dist/react-responsive-select.css';
import styles from './RealisationPage.module.css';
import {Helmet} from 'react-helmet';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const RealisationPage = () => {
  const {eventsList} = useAppSelector((s) => s.events);
  const {partnersListSimple, homePagePartner} = useAppSelector((s) => s.partners);
  const {deviceType} = useAppSelector((s) => s.main);
  const [currentClient, setCurrentClient] = useState('');
  const [currentTab, setCurrentTab] = useState<TagsTypes | null>(TagsTypes.All);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadEventsList());
    dispatch(loadPartnersListSimple());
  }, [dispatch]);

  const handleTabClick = useCallback((tab: TagsTypes | null) => {
    if (currentTab === tab) {
      setCurrentTab(null);
    } else {
      setCurrentTab(tab);
    }
  }, [currentTab]);

  const renderClients = (e: Event) => {
    for (const client of e.clients) {
      if (client.title === currentClient) {
        return (
          <div
            key={e.id}
            className={styles.realisationEventCard}
            // data-aos="fade-up"
            data-aos={deviceType === 'desktop' ? 'fade-up' : ''}
            // data-aos-delay={`${index}0`}
          >
            <EventCard event={e} width={350} />
          </div>
        );
      }
    }
    if (e.clients[0]?.title === currentClient) {
      return (
        <div
          key={e.id}
          className={styles.realisationEventCard}
          // data-aos="fade-up"
          data-aos={deviceType === 'desktop' ? 'fade-up' : ''}
        // data-aos-delay={`${index}0`}
        >
          <EventCard event={e} width={350} />
        </div>
      );
    }
    return null;
  };

  Aos.init({duration: 200});

  // Select client if const homepagepartner is set from partnerslist in homepage
  useEffect(() => {
    if (homePagePartner !== '') {
      setCurrentTab(TagsTypes.Clients);
      setCurrentClient(homePagePartner);
      dispatch(setHomePagePartner(''));
    }
  }, [homePagePartner, dispatch, partnersListSimple]);

  // Spin loader if data not loaded yet
  useEffect(() => {
    const loader = document.querySelector(`.${styles.loader}`) as HTMLElement;
    if (loader) {
      loader.animate(
        [{transform: 'rotate(0deg)'},
          {transform: 'rotate(360deg)'}],
        {duration: 650, iterations: Infinity},
      );
    }
  });

  const showTabsDesktop = () => (
      <>
      <div className={`${styles.tabsContainer}`}>
          <div
            className={[currentTab === TagsTypes.All ? styles.activeTab : '', styles.tab].join(' ')}
            onClick={() => handleTabClick(TagsTypes.All)}
          >
            TOUTES LES REALISATIONS
          </div>
          <div className={styles.separator}>&#9675; </div>
          <div
            className={[currentTab === TagsTypes.Audiovisuel ? styles.activeTab : '', styles.tab].join(' ')}
            onClick={() => handleTabClick(TagsTypes.Audiovisuel)}
          >
            AUDIOVISUEL
          </div>
          <div className={styles.separator}>&#9675; </div>
          <div
            className={[currentTab === TagsTypes.Conception ? styles.activeTab : '', styles.tab].join(' ')}
            onClick={() => handleTabClick(TagsTypes.Conception)}
          >
            CONCEPTION
          </div>
          <div className={styles.separator}>&#9675; </div>
          <div
            className={[currentTab === TagsTypes.Agence ? styles.activeTab : '', styles.tab].join(' ')}
            onClick={() => handleTabClick(TagsTypes.Agence)}
          >
            AGENCE
          </div>
          <div className={styles.separator}>&#9675; </div>
          <div
            className={[currentTab === TagsTypes.Evenementiel ? styles.activeTab : '', styles.tab].join(' ')}
            onClick={() => handleTabClick(TagsTypes.Evenementiel)}
          >
            EVENEMENTIEL
          </div>
          <div className={styles.separator}>&#9675; </div>
          <div
            className={[currentTab === TagsTypes.Expert ? styles.activeTab : '', styles.tab].join(' ')}
            onClick={() => handleTabClick(TagsTypes.Expert)}
          >
            COMMUNAUTÉ
          </div>
          <div className={styles.separator}>&#9675; </div>
          <div
            className={[currentTab === TagsTypes.Partenariats ? styles.activeTab : '', styles.tab].join(' ')}
            onClick={() => handleTabClick(TagsTypes.Partenariats)}
          >
            PARTENARIATS
          </div>

        </div>
        <div
          className={[currentTab === TagsTypes.Clients ? styles.activeTab : '', styles.tab, styles.clientTab].join(' ')}
          onClick={() => handleTabClick(TagsTypes.Clients)}
        >
          <div className={styles.separator}>&#9675; </div>
          NOS CLIENTS
          <div className={styles.separator}>&#9675; </div>
        </div>

      </>

  );
  const showTabsMobile = () => (
      <div className={styles.tabsContainerMobile}>
          {/* <div className={styles.tabsSelectorMobile}>
            <p>{`${currentTab === "" ? "TOUTES LES RÉALISATIONS" : currentTab}`}</p>
          </div> */}
          <div className={styles.tabsContentMobile}>
            <div
              className={[
                currentTab === TagsTypes.All ? styles.activeTabMobile : '',
                styles.tab, styles.mobileTab, styles.mobileTabAllRealisations,
              ].join(' ')}
              onClick={() => handleTabClick(TagsTypes.All)}
              style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg8})`}}
            >
              {currentTab === TagsTypes.All ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
              <p>Voir toutes les réalisations</p>
            </div>
            {/* // // // // */}
            {/* // ROW // */}
            <div className={[styles.mobileFirstRow, styles.mobileTabsRow].join(' ')}>
              <div
                className={[currentTab === TagsTypes.Audiovisuel ? styles.activeTabMobile : '', styles.tab, styles.mobileTab].join(' ')}
                onClick={() => handleTabClick(TagsTypes.Audiovisuel)}
                style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg1})`}}
              >
                {currentTab === TagsTypes.Audiovisuel ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                <p>Audiovisuel</p>
              </div>
              <div
                className={[currentTab === TagsTypes.Conception ? styles.activeTabMobile : '', styles.tab, styles.mobileTab].join(' ')}
                onClick={() => handleTabClick(TagsTypes.Conception)}
                style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg2})`}}
              >
              {currentTab === TagsTypes.Conception ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                <p>Conception</p>
              </div>
              <div
                className={[currentTab === TagsTypes.Agence ? styles.activeTabMobile : '', styles.tab, styles.mobileTab].join(' ')}
                onClick={() => handleTabClick(TagsTypes.Agence)}
                style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg3})`}}
              >
              {currentTab === TagsTypes.Agence ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                <p>Agence</p>
              </div>
            </div>
            {/* // // // // */}
            {/* // ROW // */}
            <div className={[styles.mobileSecondRow, styles.mobileTabsRow].join(' ')}>
              <div
                className={[currentTab === TagsTypes.Evenementiel ? styles.activeTabMobile : '', styles.tab, styles.mobileTab].join(' ')}
                onClick={() => handleTabClick(TagsTypes.Evenementiel)}
                style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg4})`}}
              >
              {currentTab === TagsTypes.Evenementiel ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                <p>Événementiel</p>
              </div>
              <div
                className={[currentTab === TagsTypes.Expert ? styles.activeTabMobile : '', styles.tab, styles.mobileTab].join(' ')}
                onClick={() => handleTabClick(TagsTypes.Expert)}
                style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg5})`}}
              >
              {currentTab === TagsTypes.Expert ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                <p>Communauté</p>
              </div>
              <div
                className={[currentTab === TagsTypes.Partenariats ? styles.activeTabMobile : '', styles.tab, styles.mobileTab].join(' ')}
                onClick={() => handleTabClick(TagsTypes.Partenariats)}
                style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg6})`}}
              >
              {currentTab === TagsTypes.Partenariats ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                <p>Partenariats</p>
              </div>
            </div>

            <div
              className={[
                currentTab === TagsTypes.Clients ? styles.activeTabMobile : '',
                styles.tab, styles.mobileTab, styles.mobileTabAllClients,
              ].join(' ')}
              onClick={() => handleTabClick(TagsTypes.Clients)}
              style={{background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${mobileTabBg7})`}}
            >
              {currentTab === TagsTypes.Clients ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
              <p>Voir tous les clients</p>
            </div>

          </div>
        </div>
  );

  const clientsBoardDesktop = () => {
    if (partnersListSimple) {
      partnersListSimple.map(() => (
        'clients desktop'
        // <div>{partner.</div>
      ));
    }
    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clientsWheelMobile = () => {
    const selectOptions = createOptions(partnersListSimple);
    console.log(selectOptions);
    const handleOnChange = (client: string) => {
      setCurrentClient(client);
    };
    return selectOptions && (
      <Select
        name="carType1"
        modalCloseButton={<ModalCloseButton />}
        options={selectOptions}
        caretIcon={<CaretIcon />}
        // prefix="Car1: "
        // selectedValue="subaru"
        onChange={(newValue: any) => handleOnChange(newValue.text)}
        onSubmit={() => console.log('onSubmit')}
      />
    );
  };

  const handleClientsTab = () => {
    if (deviceType === 'mobile') {
      return (
        <div className={styles.wheelContainer}>
          {clientsWheelMobile()}
          {/* <h3>Mobile wheel</h3> */}
        </div>
      );
    }
    // else if device type === desktop
    return (
      <div className={styles.wheelContainer}>
        {clientsBoardDesktop()}
      </div>
    );
  };

  // Mobile Wheel styling
  useEffect(() => {
    const wheel = document.querySelector('#wheelPickerID') as HTMLElement;
    const li = document.querySelectorAll('#wheelPickerID > li') as NodeListOf<HTMLElement>;
    const spans = document.querySelectorAll('#wheelPickerID > li > span') as NodeListOf<HTMLElement>;
    const paragraphItem = document.querySelectorAll('#wheelPickerID > li > p') as NodeListOf<HTMLElement>;
    if (wheel) {
      // KEEP CENTRAL ITEM UNROTATED
      if (li) {
        li.forEach((listItem) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const isSelected = listItem.attributes['aria-selected'] as any;
          console.log(isSelected);
          if (isSelected === 'true') {
            // eslint-disable-next-line no-param-reassign
            listItem.style.transform = 'rotate(0deg) translateZ(0px)';
          } else {
            // eslint-disable-next-line no-param-reassign
            listItem.style.transform = `translateZ(${100}px) rotateX(${50}deg)`;
          }
        });
        if (paragraphItem) {
          paragraphItem.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.style.margin = '0 auto';
            // eslint-disable-next-line no-param-reassign
            item.style.padding = '0';
          });
        }
        if (spans) {
          spans.forEach((span) => {
            // eslint-disable-next-line no-param-reassign
            span.style.display = 'none';
          });
        }
      }
    }
  });

  return (
    <>
    <Helmet>
      <title>ATP Réalisations</title>
      <meta
        name="description"
        content="Découvrez les opérations que la société Alt Tab réalise pour des clients prestigieux, mobilisant ses équipes expérimentées."
      />
    </Helmet>
    <img className={styles.realisationsMobileBackgroundImg} src={realisationsBackground} alt="bg" />
    <div className={styles.pageContainer}>
      <div className={styles.pageTitle}>
        <span className={styles.titleSpan}>NOS</span> RÉALISATIONS
      </div>
      <div className={styles.realisationsContent}>
        {/* / / */}
        {/* TABS DESKTOP */}
        {/* / / */}
        {deviceType === 'desktop'
        && showTabsDesktop()
        }
        {/* / / */}
        {/* TABS MOBILE */}
        {/* / / */}
        {deviceType === 'mobile'
        && showTabsMobile()
        }

        {(currentTab === TagsTypes.Clients && deviceType === 'desktop') && (
          <div className={styles.partnersList}>
            {partnersListSimple.map((partner: PartnerSimple, index:number) => (
            <div className={styles.partnerTitleContainer} key={partner._id}>
              <div onClick={() => { setCurrentClient(partner.title); }} >
                <p
                className={[currentClient === partner.title ? styles.activePartner : '', styles.partner, styles.partnerTitle].join(' ')}
                data-text={partner.title}
                >{partner.title}</p>
              </div>
              {index < partnersListSimple.length - 1 ? <p className={styles.partnerSeparator}>•</p> : ''}
            </div>
            ))}
          </div>
        )}
        {currentTab === TagsTypes.Clients && (
          handleClientsTab()
        )}
        <div className={`${styles.introduction} mb-3`}>
          {deviceType === 'desktop'
            ? <>
          <p>À la pointe des savoir-faire technologiques et narratifs en matière de vidéo,</p>
          <p>
            notre équipe d&apos;experts vous accompagnera de la conception à la réalisation de vos projets audiovisuels.
          </p>
          </>
            : <p>A la pointe des savoir-faire technologiques et narratifs en matière de vidéo,
            notre équipe d&apos;experts vous accompagnera de la conception à la réalisation de vos projets audiovisuels.
          </p>
          }
        </div>
        {eventsList.length < 1
          ? <div className={`${styles.cardContainer} `}>
            <div className={styles.loader}>
              <AiOutlineLoading3Quarters />
            </div>
        </div>
          : <div className={`${styles.cardContainer} `}>
          {!!eventsList && currentTab !== null && eventsList.map((e: Event, index) => (currentTab !== TagsTypes.Clients
            ? e.tags.includes(currentTab) && (
              <div
                key={e.id}
                className={styles.realisationEventCard}
                // data-aos="fade-up"
                data-aos={deviceType === 'desktop' ? 'fade-up' : ''}
                data-aos-delay={`${index}0`}
              >
                <EventCard event={e} width={350} />
              </div>
            )
            : renderClients(e)
          ))}
            {!!eventsList && eventsList.map((e: Event, index) => (currentTab === TagsTypes.All
              && <div
                key={e.id}
                className={styles.realisationEventCard}
                // data-aos="fade-up"
                data-aos={deviceType === 'desktop' ? 'fade-up' : ''}
                data-aos-delay={`${index}0`}
              >
                <EventCard event={e} width={350} />
              </div>
            ))}
        </div>
        }
      </div>
      <div className={styles.footerContainer}>

        <Footer />
      </div>
    </div>
    </>
  );
};

export default RealisationPage;
