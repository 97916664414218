export const createOptions = (array: any[]) => {
  const options: { value: string; text: string; }[] = [];
  if (array !== []) {
    array.forEach((e) => {
      const option = {
        // eslint-disable-next-line no-underscore-dangle
        value: e._id,
        text: e.title,
      };
      options.push(option);
    });
  }
  return options;
};
