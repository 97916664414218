/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState, useEffect} from 'react';
import {GiPauseButton, GiPlayButton} from 'react-icons/gi';
import {AiOutlineFullscreen, AiOutlineFullscreenExit} from 'react-icons/ai';
import {IoPlayCircleSharp, IoPauseCircle, IoVolumeHigh, IoVolumeLowSharp, IoVolumeMedium, IoVolumeMute} from 'react-icons/io5';
import {RootState} from 'src/app/rootReducer';
import {useSelector} from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import video from 'src/assets/videos/showreel.webm';
import styles from './HeroVideo.module.css';
// import video from '../../assets/videos/5.mp4';
import poster from '../../assets/photos/firstFrame.png';

const HeroVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [videoCurrentPos, setVideoCurrentPos] = useState('');
  const [videoDuration, setVideoDuration] = useState('');
  const [progressBarPos, setProgressBarPos] = useState(0);
  const [muted, setMuted] = useState(true);
  const [lowerButtonsIsHidden, setLowerButtonsIsHidden] = useState(true);
  const {deviceType} = useSelector((s:RootState) => s.main);
  const videoElement = document.querySelector(`.${styles.videoElement}`) as HTMLVideoElement;

  // detect fullscreen change
  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      setIsFullScreen(!isFullScreen);
    });
  }, [isFullScreen]);

  const handlePlayPause = () => {
    const playPausePop = document.querySelector(`.${styles.playPausePop}`) as HTMLElement;

    console.log(videoElement);
    // default volume 50%
    // videoElement.volume = 0.5;

    if (videoElement) {
      // pop icon play or pause
      if (videoElement.paused) {
        playPausePop.animate([
          {opacity: 0, display: 'none'}, {opacity: 1, display: 'block'}, {opacity: 0, display: 'none'},
        ], 200);
        videoElement.play();
        setIsPlaying(true);
      } else {
        playPausePop.animate([
          {opacity: 0, display: 'none'}, {opacity: 1, display: 'block'}, {opacity: 0, display: 'none'},
        ], 200);
        videoElement.pause();
        setIsPlaying(false);
      }
    }
    videoElement.animate(
      [
        {filter: 'contrast(80%)'},
        {filter: 'contrast(100%)'},
      ],
      80,
    );

    // Hide controls in mobile after press play
    if (deviceType === 'mobile') {
      const buttonsLowerSection = document.querySelector(`.${styles.buttonsLowerSection}`) as HTMLElement;
      if (buttonsLowerSection) {
        if (!isPlaying) {
          setTimeout(() => {
            buttonsLowerSection.animate([
              {transform: 'translateY(0%)'},
              {transform: 'translateY(100%)'},
            ], 300);
            buttonsLowerSection.style.transform = 'translateY(100%)';
          }, 300);
        } else if (isPlaying) {
          setTimeout(() => {
            buttonsLowerSection.style.opacity = '0';
            buttonsLowerSection.style.transform = 'translateY(0%)';
            buttonsLowerSection.animate([
              {opacity: '0'},
              {opacity: '1'},
            ], 200);
            buttonsLowerSection.style.opacity = '1';
          }, 200);
        }
      }
    }
  };
  const handleFullScreen = () => {
    const videoContainer = document.querySelector(`.${styles.HeroVideoContainer}`);
    // function getFullScreenElement () { return document.fullscreenElement }
    if (videoContainer) {
      if (document.fullscreenElement === null) {
        videoContainer.requestFullscreen().then((err) => {
          console.log(err);
        });
        setTimeout(() => {
          window.screen.orientation.lock('landscape').then(null, () => {
            document.exitFullscreen();
          });
        });
      } else {
        document.exitFullscreen().then((err) => {
          console.log(err);
        });
      }
    }
  };
  const handleVideoProgress = () => {
    const videoPlayer = document.querySelector(`.${styles.videoElement}`) as HTMLVideoElement;
    if (videoPlayer) {
      const currentMinutes = Math.floor(videoPlayer.currentTime / 60);
      const currentSeconds = Math.floor(videoPlayer.currentTime - currentMinutes * 60);
      const durationMinutes = Math.floor(videoPlayer.duration / 60);
      const durationSeconds = Math.floor(videoPlayer.duration - durationMinutes * 60);

      const currentPosString = `${currentMinutes}:${currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds}`;
      const durationString = `${durationMinutes}:${durationSeconds < 10 ? `0${durationSeconds}` : durationSeconds}`;

      setProgressBarPos((100 * videoPlayer.currentTime) / videoPlayer.duration);
      setVideoCurrentPos(currentPosString);
      setVideoDuration(durationString);
    }
  };

  // Handle current time change
  useEffect(() => {
    const videoPlayer = document.querySelector(`.${styles.videoElement}`) as HTMLVideoElement;
    const videoProgress = document.querySelector(`.${styles.videoProgress}`) as HTMLElement;
    if (videoPlayer && videoProgress) {
      videoProgress.addEventListener('click', (e) => {
        const progressTime = (e.offsetX / videoProgress.offsetWidth) * videoPlayer.duration;
        videoPlayer.currentTime = progressTime;
      });
    }
  });

  const handleVolumeChange = (e:any) => {
    const videoPlayer = document.querySelector(`.${styles.videoElement}`) as HTMLVideoElement;
    if (videoPlayer) {
      if (muted) {
        videoPlayer.volume = 0;
      } else {
        videoPlayer.volume = e.target.value;
      }
    }
  };

  const renderVolumeIcon = () => {
    const videoPlayer = document.querySelector(`.${styles.videoElement}`) as HTMLVideoElement;
    const volumeButton = document.querySelector(`.${styles.volume}`) as HTMLInputElement;
    if (videoPlayer && volumeButton) {
      if (muted || videoPlayer.volume === 0) return <IoVolumeMute />;
      if (videoPlayer.volume > 0 && videoPlayer.volume < 0.3) return <IoVolumeLowSharp />;
      if (videoPlayer.volume >= 0.3 && videoPlayer.volume < 0.6) return <IoVolumeMedium />;
      if (videoPlayer.volume >= 0.6) return <IoVolumeHigh />;
    }
    return null;
  };

  const timer = setTimeout(() => {
    if (!lowerButtonsIsHidden) {
      setLowerButtonsIsHidden(true);
    }
  }, 2000);

  // handle hide cursor
  useEffect(() => {
    if (deviceType === 'desktop') {
      const playPauseInvisibleButton = document.querySelector(`.${styles.playPauseInvisibleButton}`) as HTMLElement;
      if (playPauseInvisibleButton) {
        setTimeout(() => {
          if (lowerButtonsIsHidden && isPlaying) playPauseInvisibleButton.style.cursor = 'none';
        }, 2000);
        if (!lowerButtonsIsHidden) playPauseInvisibleButton.style.cursor = 'default';
      }
    }
  }, [deviceType, isPlaying, lowerButtonsIsHidden]);

  // if user scroll down, pause video
  useEffect(() => {
    const pauseOnScroll = (e:any) => {
      const videoPlayer = document.querySelector(`.${styles.videoElement}`) as HTMLVideoElement;
      if (videoPlayer) {
        const rect = videoPlayer.getBoundingClientRect();
        if (rect.top < 1 && rect.bottom > 0 && !(videoPlayer.paused)) {
          if (e.deltaY > 0) {
            videoPlayer.pause();
            setIsPlaying(false);
          }
        }
      }
    };
    window.addEventListener('wheel', (e) => { pauseOnScroll(e); }, false);
  }, []);

  return (
    <div
      onDoubleClick={() => {
        handleFullScreen();
      }}
      className={styles.HeroVideoContainer}
      id="heroVideoContainerID"
      onMouseMove={() => {
        if (deviceType === 'desktop') {
          setLowerButtonsIsHidden(false);
          clearTimeout(timer);
        }
      }}
    >
      <video
      src={`${video}`}
      className={styles.videoElement}
      style={{height: isFullScreen ? '100%' : '', width: isFullScreen ? '' : '100%'}}
      muted={muted}
      autoPlay
      // loop
      preload="none"
      poster={poster}
      onTimeUpdate={(e) => {
        handleVideoProgress();
      }}
      />
      <div className={styles.playerControls}>
          <div className={styles.buttonsUpperSection}>
            <span className={styles.playPauseInvisibleButton}
              onClick={() => {
                handlePlayPause();
              }}
              aria-hidden
            />
            <span className={styles.playPausePop}>
              {!isPlaying ? <IoPauseCircle /> : <IoPlayCircleSharp />}
            </span>
          </div>
          <div className={styles.buttonsLowerSection} style={{opacity: lowerButtonsIsHidden && deviceType === 'desktop' ? 0 : 1}} >
            <div className={styles.videoProgress}>
              <span className={styles.videoProgressFilled} style={{width: `${progressBarPos}%`}}/>
              <div
              className={styles.videoProgressDot}
              style={{left: `${progressBarPos - 1}%`}}
              />
            </div>
            <div className={styles.lowerButtonsWrapper}>

              <div className={styles.lowerButtonsLeft}>
                <button
                  className={styles.buttonPlayPause}
                  onClick={() => {
                    handlePlayPause();
                  }}
                >
                  <span>{isPlaying ? <GiPauseButton /> : <GiPlayButton />}</span>
                </button>
                <div className={styles.videoTimer}>
                  { videoCurrentPos
                    ? <p className={styles.videoTimerString}>{videoCurrentPos} / {videoDuration}</p>
                    : <p className={styles.videoTimerString}>0:00 / 1:34</p>
                  }
                </div>
                <div className={styles.volumeIconContainer} onClick={() => { setMuted(!muted); }} aria-hidden>
                  {deviceType === 'desktop'
                  && (renderVolumeIcon() ? renderVolumeIcon() : <IoVolumeMedium />)
                  }
                  {deviceType === 'mobile'
                  && (!muted ? <IoVolumeMedium /> : <IoVolumeMute />)
                  }
                </div>
                {deviceType === 'desktop'
                && <input
                type="range" className={styles.volume}
                defaultValue="0.5"
                min="0" max="1"
                step="0.1"
                onChange={(e:any) => {
                  // setVolume(e.target.value)
                  handleVolumeChange(e);
                }}
                />
                }
              </div>
              <div className={styles.lowerButtonsRight}>
              {deviceType === 'desktop'
              && <div className={styles.lowerButtonsText}>
                  {
                    document.fullscreenElement === null
                    && <p style={{margin: '3px 0px 0px 0px'}}>
                      L&apos;agence de{' '}
                      <span style={{fontFamily: 'hussarbold'}}>talents</span>
                    </p>
                  }
                </div>
              }
                <button className={styles.buttonFullScreen}onClick={() => { handleFullScreen(); }}>
                  <span>{isFullScreen ? (<AiOutlineFullscreenExit />) : (<AiOutlineFullscreen />) }</span>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default HeroVideo;
