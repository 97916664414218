/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {Event} from './events';
import {AppThunk} from './helpers';

export interface Talent {
  _id: string;
  title: string;
  description: string;
  firstname: string;
  lastname: string;
  nickname: string;
  photo: string;
  nationality: string;
  game: string;
  teamName: string;
  birth_date: Date;
  member_since: Date;
  events: Event[] | null;
  displayRank: number;
}

export interface TalentSimple {
  _id: string;
  title: string;
  description: string;
  firstname: string;
  lastname: string;
  nickname: string;
  photo: string;
  nationality: string;
  game: string;
  teamName: string;
  birth_date: Date;
  member_since: Date;
  events: string[];
  displayRank: number;
}
interface TalentState {
  talentsList: Talent[];
  talentsListSimple: TalentSimple[];
}

const initialState: TalentState = {
  talentsList: [],
  talentsListSimple: [],
};

const talents = createSlice({
  name: 'talents',
  initialState,
  reducers: {
    talentsLoaded(state, {payload}: PayloadAction<Talent[]>) {
      state.talentsList = payload;
    },
    simpleTalentsLoaded(state, {payload}: PayloadAction<TalentSimple[]>) {
      state.talentsListSimple = payload;
    },
  },
});

export const {talentsLoaded, simpleTalentsLoaded} = talents.actions;
export default talents.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadTalentsList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/talents/fetch/all`);
    dispatch(talentsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadTalentsListSimple = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/talents/fetch/all/simple`);
    dispatch(simpleTalentsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};
