import {useNavigate} from 'react-router-dom';
import altTabLogo from '../../assets/logo/logo_horizontal_simple.png';
import styles from './ContactUsAndLogo.module.css';

interface ContactUsAndLogoProps {
  // eslint-disable-next-line react/require-default-props
  noLogo?: boolean;
}
const ContactUsAndLogo = ({noLogo}: ContactUsAndLogoProps) => {
  const navigate = useNavigate();


  return (
    <div
      className={`d-flex ${styles.titleContainer}`}
      onClick={() => navigate('/contact')}
    >
      <div className={styles.title}>
        <p>CONTACTEZ <span className={styles.span}>NOUS</span></p>
        {!noLogo && (
          <img
            src={altTabLogo}
            alt="logo-atp"
            className={styles.image}
          />
        )}
      </div>
    </div>
  );
};

export default ContactUsAndLogo;
